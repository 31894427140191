import React, { useState, Fragment } from 'react';
import { ReactFlow, Handle } from '@xyflow/react';
import { Listbox, Transition, Switch } from '@headlessui/react';
import { Tooltip } from 'flowbite-react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { tests } from '../ModelEvaluations/MixEvalTests';

import '@xyflow/react/dist/style.css';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const FlowPipeline = ({
  formData,
  updateField,
  updateEvalTest,
  stepStyle,
  currentStep,
  baseModels,
  deployedModels,
  datasets,
  uniqueTags,
}) => {
  const selectedBaseModel = formData?.base_model || '';
  const selectedDeployedModel = formData?.deployed_model || '';
  const [defaultBatchSize, setDefaultBatchSize] = useState('');
  const [defaultLearningRate, setDefaultLearningRate] = useState('');
  const groupedModels = (models) => {
    if (!models || models.length === 0) {
      return [];
    }

    const families = {
      Mistral: [],
      Mixtral: [],
      LLaMA: [],
      Gemma: [],
      Phi: [],
      Databricks: [],
      Others: [],
    };

    models.forEach((model) => {
      if (model.model_name.includes('mistral')) {
        families.Mistral.push(model);
      } else if (model.model_name.includes('mixtral')) {
        families.Mixtral.push(model);
      } else if (model.model_name.includes('llama')) {
        families.LLaMA.push(model);
      } else if (model.model_name.includes('gemma')) {
        families.Gemma.push(model);
      } else if (model.model_name.includes('phi')) {
        families.Phi.push(model);
      } else if (model.model_name.includes('dbrx')) {
        families.Databricks.push(model);
      } else {
        families.Others.push(model);
      }
    });

    return Object.keys(families).map((family) => ({
      family,
      models: families[family],
    }));
  };

  const getImageUrlForFamily = (family) => {
    switch (family) {
      case 'Mistral':
        return '/mistral.png';
      case 'Mixtral':
        return '/mistral.png';
      case 'LLaMA':
        return '/meta.png';
      case 'Gemma':
        return '/googleicon.png';
      case 'Phi':
        return '/microsoft.svg';
      case 'Databricks':
        return '/databricks.png';
      default:
        return null;
    }
  };
  // Custom Node Components

  const stepIndex = 0;

  const JobInitialization = ({ formData, updateField, stepIndex }) => {
    const [localValueJobName, setLocalValueJobName] = useState(
      formData?.jobName || '',
    );

    const [localValueAlias, setLocalValueAlias] = useState(
      formData?.modelAlias?.startsWith('alias_')
        ? formData?.modelAlias
        : 'alias_',
    );

    // const [selectedModelAlias, setSelectedModelAlias] = useState(
    //   formData.modelAlias || '',
    // );

    // const modelAliasOptions = ['Alias 10000', 'Alias 20000', 'Alias 30000'];

    const handleBlurJobName = () => {
      updateField('jobName', localValueJobName); // Update global state for job name
    };

    const handleBlurAlias = () => {
      updateField('modelAlias', localValueAlias); // Update global state for alias
    };

    const handleAliasChange = (e) => {
      const inputValue = e.target.value;
      // Ensure that 'alias_' is always the prefix
      if (inputValue.startsWith('alias_')) {
        setLocalValueAlias(inputValue); // Allow user to modify after 'alias_'
      }
    };

    return (
      <div
        className={`${stepStyle(
          stepIndex,
        )} custom-node h-[580px] w-64 rounded-lg shadow bg-zinc-50/70 py-4`}
      >
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                Choose a name for your continuous training pipeline job. You
                won't be able to change this later.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">Job initialization</label>
        <hr className="my-2 border-gray-300" />
        <div className="px-4 mb-4">
          <label className="mb-4">Job name</label>
          <input
            type="text"
            value={localValueJobName}
            onChange={(e) => setLocalValueJobName(e.target.value)}
            onBlur={handleBlurJobName}
            className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border focus:ring-0 ring-0 focus:ring-zinc-800 sm:text-sm border-zinc-500 border my-4"
            placeholder="Enter Job Name"
          />
        </div>
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                Choose an alias for your model. You can use this alias to refer
                to your model throughout the training pipeline.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="mb-4 px-4">Model alias</label>
        <div className="px-4 mb-4">
          <input
            type="text"
            value={localValueAlias}
            onChange={handleAliasChange}
            onBlur={handleBlurAlias}
            className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border focus:ring-0 ring-0 focus:ring-zinc-800 sm:text-sm border-zinc-500 border my-4"
            placeholder="Create a model alias"
          />
        </div>
        <Handle type="source" position="right" />
      </div>
    );
  };

  const DropdownNode = ({
    data,
    formData,
    updateField,
    baseModels,
    groupedModels,
    getImageUrlForFamily,
    selectedBaseModel,
    selectedDeployedModel,
  }) => {
    // const selectedBaseModel = formData?.base_model || '';
    // const selectedDeployedModel = formData?.deployed_model || '';
    const [selectedModelType, setSelectedModelType] = useState(''); // Track the selected model type ('base' or 'deployed')

    // Filter base models and deployed models so that one type of model is selectable
    const filteredBaseModels = baseModels.filter(
      (model) => model.model_name !== selectedBaseModel,
    );
    const filteredDeployedModels = deployedModels.filter(
      (model) => model.model_name !== selectedDeployedModel,
    );

    const groupedModelsList = groupedModels(baseModels);

    // console.log('defaultBatchSize', defaultBatchSize);
    // console.log('defaultLearningRate', defaultLearningRate);
    // console.log('selectedBaseModel', selectedBaseModel);

    const handleModelSelection = (value, type) => {
      if (type === 'base') {
        const selectedBaseModel = baseModels.find(
          (model) => model.model_name === value,
        );
        if (selectedBaseModel) {
          updateField('base_model', selectedBaseModel.model_name);
          updateField('base_model_id', selectedBaseModel.model_id); // Store the base model ID
          setDefaultBatchSize(selectedBaseModel.default_batch_size);
          setDefaultLearningRate(selectedBaseModel.default_lr);
        }
        updateField('deployed_model', ''); // Clear deployed model if base model is selected
        updateField('model_id', null); // Clear deployed model_id
      } else if (type === 'deployed') {
        const selectedDeployedModel = deployedModels.find(
          (model) => model.model_name === value,
        );
        if (selectedDeployedModel) {
          updateField('deployed_model', selectedDeployedModel.model_name);
          updateField('model_id', selectedDeployedModel.model_id); // Store the deployed model ID
          setDefaultBatchSize(
            selectedDeployedModel.base_model_data.default_batch_size,
          );
          setDefaultLearningRate(
            selectedDeployedModel.base_model_data.default_lr,
          );
        }
        updateField('base_model', ''); // Clear base model if deployed model is selected
        updateField('base_model_id', null); // Clear base model_id
      }
      setSelectedModelType(type); // Track the type of model selected
    };

    return (
      <div className="custom-node border h-[580px] w-64 rounded-lg shadow bg-zinc-50/70 py-4">
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                Choose a model to base this training job on. You can begin with
                a base model or one of your fine-tuned models.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">{data.label}</label>
        <hr className="my-2 border-gray-300" />
        <label className="px-4">Select a base model</label>
        <div className="p-4 z-">
          <Listbox
            className="z-10"
            value={selectedModelType === 'base' ? selectedBaseModel : null}
            onChange={(value) => handleModelSelection(value, 'base')}
            disabled={selectedModelType === 'fine-tuned'}
          >
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none sm:text-sm border-zinc-500 border">
                <span className="block truncate">
                  {selectedModelType === 'base'
                    ? selectedBaseModel
                    : 'Select a base model'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {/* Grouped Models */}
                  {groupedModels(filteredBaseModels)?.map((group) => (
                    <Fragment key={group.family}>
                      <div className="px-4 py-2 font-semibold text-gray-900 bg-gray-100">
                        <div className="flex flex-row items-center justify-start">
                          <img
                            src={getImageUrlForFamily(group.family)}
                            alt={null}
                            className="w-auto h-5 mr-2"
                          />
                          {group.family}
                        </div>
                      </div>
                      {group.models.map((model) => (
                        <Listbox.Option
                          key={model.model_name}
                          value={model.model_name}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'}`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                              >
                                {model.display_name}
                              </span>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Fragment>
                  ))}
                  <Listbox.Option
                    className="relative py-2 pl-10 pr-4 text-gray-300 select-none"
                    disabled
                  >
                    <span className="block font-normal truncate">
                      More base models coming soon
                    </span>
                  </Listbox.Option>
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="flex items-center justify-center mb-4">or</div>
        <label className="px-4 mt-2">Select a fine-tuned model</label>
        <div className="p-4">
          {/* users finetuned models */}
          <Listbox
            value={
              selectedModelType === 'deployed' ? selectedDeployedModel : null
            } // Show selected model if it's fine-tuned
            onChange={(value) => handleModelSelection(value, 'deployed')}
            disabled={selectedModelType === 'base'}
          >
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none sm:text-sm border-zinc-500 border">
                <span className="block truncate">
                  {selectedModelType === 'fine-tuned'
                    ? selectedDeployedModel
                    : 'Select a fine-tuned model'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {/* Iterate over deployedModels */}
                  {filteredDeployedModels.map((model) => (
                    <Listbox.Option
                      key={model.model_name}
                      value={model.model_name}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-indigo-100 text-indigo-900'
                            : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {model.model_name}{' '}
                            {/* Display fine-tuned model name */}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  {/* Fallback option */}
                  <Listbox.Option
                    className="relative py-2 pl-10 pr-4 text-gray-300 select-none"
                    disabled
                  >
                    <span className="block font-normal truncate">
                      Deploy a fine-tuned model
                    </span>
                  </Listbox.Option>
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <Handle type="source" position="right" />
        <Handle type="target" position="left" />
      </div>
    );
  };

  const DataNode = ({ data, formData, updateField, datasets, uniqueTags }) => {
    const selectedTags = formData?.tags;
    const selectedDatasets = formData?.dataset;

    const [localValueNumLogs, setLocalValueNumLogs] = useState(
      formData?.logsThreshold || '',
    );
    const handleBlurNumLogs = () => {
      updateField('logsThreshold', localValueNumLogs);
    };

    return (
      <div className="custom-node border h-[580px] w-64 rounded-lg shadow bg-zinc-50/70 py-4">
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2 z-20"
            content={
              <div className="w-48">
                Choose the data you want to use for this training job. You can
                select tags to filter logs or datasets to use to train the
                model.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">Data</label>
        <hr className="my-2 border-gray-300" />

        {/* Tags Dropdown */}
        <label className="px-4">Select Tags</label>
        <div className="p-4 mb-4 ">
          <Listbox
            className="z-20"
            value={selectedTags}
            onChange={(value) => updateField('tags', value)} // Update global state
            multiple
          >
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none sm:text-sm border-zinc-500 border">
                <span className="block truncate">
                  {selectedTags?.length > 0
                    ? selectedTags?.join(', ')
                    : 'Select tags'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {uniqueTags?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      value={option}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-indigo-100 text-indigo-900'
                            : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {option}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        {/* Datasets Dropdown */}
        <label className="p-4">Select Dataset</label>
        <div className="p-4 z-0 mb-2">
          <Listbox
            className="z-10"
            value={selectedDatasets}
            onChange={(value) => updateField('dataset', value)} // Update global state
            multiple
          >
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none sm:text-sm border-zinc-500 border">
                <span className="block truncate">
                  {selectedDatasets?.length > 0
                    ? selectedDatasets?.join(', ')
                    : 'Select datasets'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {datasets.map((dataset) => (
                    <Listbox.Option
                      key={dataset.id}
                      value={dataset.name}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-indigo-100 text-indigo-900'
                            : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {dataset.name}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        <div className="flex items-center justify-end  h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2 z-20"
            content={
              <div className="w-48">
                Select the minimum number of logs which needs to accumulate in
                order to trigger the training process to begin.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">Training threshold</label>
        <hr className="my-2 border-gray-300" />
        <label className="px-4">Number of logs</label>
        <div className="p-4">
          <input
            type="text"
            value={localValueNumLogs}
            onChange={(e) => {
              const value = e.target.value;

              // Only allow numbers and prevent negative values
              if (/^\d*$/.test(value)) {
                setLocalValueNumLogs(value);
              }
            }}
            onBlur={handleBlurNumLogs}
            className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border focus:ring-0 ring-0 focus:ring-zinc-800 sm:text-sm border-zinc-500 border"
          />
        </div>

        <Handle type="source" position="right" />
        <Handle type="target" position="left" />
      </div>
    );
  };

  const DataEnhanceNode = ({ data, formData, updateField }) => {
    return (
      <div className="custom-node border h-[580px] w-64 rounded-lg shadow bg-zinc-50/70 py-4">
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2 z-20"
            content={
              <div className="w-48">
                Enchance the data by synthesizing logs or improving logs prior
                to training the model.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">Data Enhancements</label>
        <hr className="my-2 border-gray-300" />
        <div className="p-4 mb-2">
          <div>
            <input
              type="checkbox"
              checked={formData?.synthetic}
              onChange={(e) => updateField('synthetic', e.target.checked)}
              className="appearance-none h-4 w-4 border border-gray-300 rounded checked:bg-zinc-800 checked:border-transparent focus:outline-none"
            />
            <label className="ml-2">Synthesize logs</label>
          </div>
          <div className="mt-2">
            <input
              type="checkbox"
              checked={formData?.improved}
              onChange={(e) => updateField('improved', e.target.checked)}
              className="appearance-none h-4 w-4 border border-gray-300 rounded checked:bg-zinc-800 checked:border-transparent focus:outline-none"
            />
            <label className="ml-2">Improve logs</label>
          </div>
        </div>
        <Handle type="source" position="right" />
        <Handle type="target" position="left" />
      </div>
    );
  };

  const EvaluationNode = ({ updateEvalTest, formData, tests }) => {
    return (
      <div className="custom-node border h-[580px] w-64 rounded-lg shadow bg-zinc-50/70 py-4">
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                Select the evaluation tests you want to run on the model. Custom
                evaluations will be coming soon.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">Evaluation</label>
        <hr className="my-2 border-gray-300" />

        <div className="p-4 grid grid-cols-1 gap-1">
          {tests.map((test, index) => (
            <div key={test.id} className="flex items-center">
              <input
                type="checkbox"
                checked={formData?.evalTests[index] || false} // Access the corresponding index in the array
                onChange={(e) => updateEvalTest(index, e.target.checked)} // Update test selection
                className="appearance-none h-4 w-4 border border-gray-300 rounded checked:bg-zinc-800 checked:border-transparent focus:outline-none"
              />
              <label className="ml-2">{test.displayName}</label>
            </div>
          ))}
        </div>

        <Handle type="source" position="right" />
        <Handle type="target" position="left" />
      </div>
    );
  };

  const DeployNode = ({
    formData,
    updateField,
    tests,
    selectedBaseModel,
    selectedDeployedModel,
  }) => {
    const [deployEnabled, setDeployEnabled] = useState(formData?.deploy);
    const [localThresholds, setLocalThresholds] = useState(
      formData?.improvementThresholds || Array(tests.length).fill(''),
    );

    // console.log('formdata.imprvementThresholds:', formData.improvementThresholds);

    const [epochs, setEpochs] = useState(formData?.epochs || '');
    const [batchSize, setBatchSize] = useState(formData?.batch_size || '');
    const [learningRate, setLearningRate] = useState(
      formData?.learning_rate || '',
    );

    // Handle changes in the input field for a specific test
    const handleChangeImprovementThreshold = (e, index) => {
      const value = e.target.value;

      // Regex: only allow values between 0-100 with one decimal place max
      if (/^(\d{0,2}(\.\d{0,1})?)?$/.test(value)) {
        const updatedThresholds = [...localThresholds];
        updatedThresholds[index] = value;

        // Update the formData directly via the updateField function
        setLocalThresholds(updatedThresholds);
      }
    };

    // Handle blur event to validate and update the global state
    const handleBlurEval = (index) => {
      let value = parseFloat(localThresholds[index]);

      // Ensure the value is between 0 and 100
      if (isNaN(value)) {
        value = 0; // Default to 0 if the input is invalid
      } else if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }

      const updatedThresholds = [...localThresholds];
      updatedThresholds[index] = value.toFixed(1);

      // Update the global state with the validated value
      updateField('improvementThresholds', updatedThresholds);
    };

    // Handle input changes for epochs, batch size, and learning rate
    const handleEpochsChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) setEpochs(value);
    };

    const handleBatchSizeChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) setBatchSize(value);
    };

    const handleLearningRateChange = (e) => {
      const value = e.target.value;
      if (/^(\d*(\.\d{0,4})?)$/.test(value)) setLearningRate(value);
    };

    const handleBlurEpochs = () => {
      updateField('epochs', epochs || 0);
    };

    const handleBlurBatchSize = () => {
      updateField('batch_size', batchSize || 0);
    };

    const handleBlurLearningRate = () => {
      updateField('learning_rate', learningRate || '0.0000');
    };

    // Safeguard to ensure that formData.evalTests and tests are both arrays
    const selectedTests = (formData?.evalTests || [])
      .map((isSelected, index) => {
        if (isSelected && tests && tests[index]) {
          return { test: tests[index], index }; // Keep the original index
        }
        return null;
      })
      .filter(Boolean);

    // console.log('Tests:', tests);
    // console.log('Selected tests:', selectedTests);
    // console.log('formData:', formData);
    // console.log('selectedBaseModel:', selectedBaseModel);
    // console.log('selectedDeployedModel:', selectedDeployedModel);

    return (
      <div className="custom-node border h-[580px] w-64 rounded-lg shadow bg-zinc-50/70 py-4">
        <div className="flex items-center justify-end -mt-6 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                Define the conditions for deploying the model. You can set the
                improvement thresholds for each evaluation test.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="px-4">Deployment</label>
        <hr className="my-2 border-gray-300" />
        <div className="flex items-center justify-end -mt-4 h-2 pr-3 pt-6">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                The improvement threshold is the minimum percentage improvement
                which the model evaluation tests must meet in order to trigger
                the deployment of the model.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        <label className="mb-2 px-4">Improvement Thresholds</label>
        <div className="px-4 max-h-40 overflow-y-auto mt-2">
          {selectedTests?.length > 0 ? (
            selectedTests.map(({ test, index }) => (
              <div key={test.nameToBackend} className="mb-4">
                <label className="block font-medium">{test.displayName}</label>
                <input
                  type="text"
                  value={
                    localThresholds[index] !== undefined
                      ? `${localThresholds[index]}%`
                      : ''
                  }
                  onChange={(e) => handleChangeImprovementThreshold(e, index)}
                  onBlur={() => handleBlurEval(index)}
                  className="w-full mt-1 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 shadow-md focus:outline-none sm:text-sm border-zinc-500 border"
                  placeholder="Enter % Improvement"
                />
              </div>
            ))
          ) : (
            <div className="italic text-zinc-500 border p-1 rounded ">
              No selected evaluation tests
            </div>
          )}
        </div>
        <div className="flex items-center justify-end mt-6 h-2 pr-3 ">
          <Tooltip
            className="flex mr-2 mt-2"
            content={
              <div className="w-48">
                These are the training configurations for the model. You can set
                the number of epochs, batch size, and learning rate.
              </div>
            }
            placement="left"
          >
            <span className="cursor-pointer">
              <InformationCircleIcon className="h-5 w-5" />
            </span>
          </Tooltip>
        </div>
        {/* <div className="flex items-center mt-2 px-4">
          <label className="mr-2">Deploy model</label>
          <Switch
            checked={deployEnabled}
            onChange={(enabled) => {
              setDeployEnabled(enabled);
              updateField('deploy', enabled);
            }}
            className={`${deployEnabled ? 'bg-zinc-900' : 'bg-zinc-700'}
            relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
          >
            <span className="sr-only">Use deploy setting</span>
            <span
              className={`${deployEnabled ? 'translate-x-6' : 'translate-x-0'}
              pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div> */}
        <label className="mr-2 px-4">Training Configuration</label>
        <hr className="mt-2 border-gray-300" />
        <div className=" p-4 pt-3 ">
          <label className="block font-medium">Epochs</label>
          <input
            type="text"
            value={epochs || 1}
            onChange={handleEpochsChange}
            onBlur={handleBlurEpochs}
            className="w-full mt-1 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 shadow-md focus:outline-none sm:text-sm border-zinc-500 border"
            placeholder="Enter number of epochs"
          />

          <label className="block font-medium mt-4">Batch Size</label>
          <input
            type="text"
            value={batchSize || defaultBatchSize}
            onChange={handleBatchSizeChange}
            onBlur={handleBlurBatchSize}
            className="w-full mt-1 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 shadow-md focus:outline-none sm:text-sm border-zinc-500 border"
            placeholder="Enter batch size"
          />

          <label className="block font-medium mt-4">Learning Rate</label>
          <input
            type="text"
            value={learningRate || defaultLearningRate}
            onChange={handleLearningRateChange}
            onBlur={handleBlurLearningRate}
            className="w-full mt-1 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 shadow-md focus:outline-none sm:text-sm border-zinc-500 border"
            placeholder="Enter learning rate"
          />
        </div>

        <Handle type="target" position="left" />
      </div>
    );
  };

  const initialNodes = [
    {
      id: '1',
      position: { x: 0, y: -20 },
      data: {
        label: 'Job name',
        value: '',
        onChange: (e) => console.log('Text input value:', e.target.value),
      },
      type: 'textInput',
    },
    {
      id: '2',
      position: { x: 300, y: -20 },
      data: {
        label: 'Select a model',
        value: '',
        onChange: (e) => console.log('Dropdown value:', e.target.value),
        options: ['Option 1', 'Option 2', 'Option 3'],
      },
      type: 'dropdown',
    },
    {
      id: '3',
      position: { x: 600, y: -20 },
      data: {
        logTagValue: '',
        onLogTagChange: (e) => console.log('Log tag value:', e.target.value),
        logTagOptions: ['testing', 'michael-1', 'model-3-testing'],
        datasetValue: '',
        onDatasetChange: (e) => console.log('Dataset value:', e.target.value),
        datasetOptions: ['Dataset 1', 'Dataset 2', 'Dataset 3'],
        synthesizeLogs: false,
        onSynthesizeLogsChange: (e) =>
          console.log('Synthesize logs checked:', e.target.checked),
        improveLogs: false,
        onImproveLogsChange: (e) =>
          console.log('Improve logs checked:', e.target.checked),
        label: 'Number of logs',
        value: '',
        onChange: (e) => console.log('Number input value:', e.target.value),
      },
      type: 'DataNode',
    },
    {
      id: '4',
      position: { x: 900, y: -20 },
      data: {
        logTagValue: '',
        onLogTagChange: (e) => console.log('Log tag value:', e.target.value),
        logTagOptions: ['testing', 'michael-1', 'model-3-testing'],
        datasetValue: '',
        onDatasetChange: (e) => console.log('Dataset value:', e.target.value),
        datasetOptions: ['Dataset 1', 'Dataset 2', 'Dataset 3'],
        synthesizeLogs: false,
        onSynthesizeLogsChange: (e) =>
          console.log('Synthesize logs checked:', e.target.checked),
        improveLogs: false,
        onImproveLogsChange: (e) =>
          console.log('Improve logs checked:', e.target.checked),
        label: 'Number of logs',
        value: '',
        onChange: (e) => console.log('Number input value:', e.target.value),
      },
      type: 'DataEnhanceNode',
    },
    // {
    //   id: '4',
    //   position: { x: 900, y: -20 },
    //   data: {
    //     synthesizeLogs: false,
    //     onSynthesizeLogsChange: (e) =>
    //       console.log('Synthesize logs checked:', e.target.checked),
    //     improveLogs: false,
    //     onImproveLogsChange: (e) =>
    //       console.log('Improve logs checked:', e.target.checked),
    //   },
    //   type: 'checkboxNode',
    // },
    // {
    //   id: '5',
    //   position: { x: 1200, y: -20 },
    //   data: {
    //     label: 'Number of logs',
    //     value: '',
    //     onChange: (e) => console.log('Number input value:', e.target.value),
    //   },
    //   type: 'numberInput',
    // },
    {
      id: '6',
      position: { x: 1200, y: -20 },
      data: {
        evaluationImprovement: '',
        onEvaluationImprovementChange: (e) =>
          console.log('Evaluation Improvement (%) value:', e.target.value),
        onCheckboxChange: (e, name) =>
          console.log(`${name} checkbox value:`, e.target.checked),
      },
      type: 'evaluationNode',
    },
    {
      id: '7',
      position: { x: 1500, y: -20 },
      data: {
        toggleDeploy: false,
        onToggleDeployChange: (e) =>
          console.log('Toggle deploy:', e.target.checked),
        option1: false,
        onOption1Change: (e) => console.log('Option 1:', e.target.checked),
        option2: false,
        onOption2Change: (e) => console.log('Option 2:', e.target.checked),
      },
      type: 'deployNode',
    },
  ];

  const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' }, // Job name -> Select a base model
    { id: 'e2-3', source: '2', target: '3' }, // Select a base model -> Select log tags & dataset
    //{ id: 'e3-4', source: '3', target: '4' }, // Select log tags & dataset -> Improve & refine data
    //{ id: 'e4-5', source: '4', target: '5' }, // Improve & refine data -> Number of logs
    { id: 'e3-4', source: '3', target: '4' }, // Number of logs -> Evaluation
    { id: 'e4-6', source: '4', target: '6' }, // Number of logs -> Evaluation
    { id: 'e6-7', source: '6', target: '7' }, // Evaluation -> Deploy
  ];

  // const nodeTypes = {
  //   textInput: JobInitialization,
  //   logTagsAndDataset: DataNode,
  //   checkboxNode: CheckboxNode,
  //   numberInput: NumberInputNode,
  //   dropdown: DropdownNode,
  //   evaluationNode: EvaluationNode,
  //   deployNode: DeployNode,
  // };

  return (
    <div className="bg-transparent" style={{ width: '100vw', height: '500px' }}>
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        nodeTypes={{
          textInput: (props) => (
            <JobInitialization
              {...props}
              formData={formData}
              updateField={updateField}
            />
          ),
          DataNode: (props) => (
            <DataNode
              {...props}
              formData={formData}
              updateField={updateField}
              datasets={datasets}
              uniqueTags={uniqueTags}
            />
          ),
          DataEnhanceNode: (props) => (
            <DataEnhanceNode
              {...props}
              formData={formData}
              updateField={updateField}
            />
          ),
          dropdown: (props) => (
            <DropdownNode
              {...props}
              formData={formData}
              updateField={updateField}
              baseModels={baseModels}
              groupedModels={groupedModels}
              getImageUrlForFamily={getImageUrlForFamily}
              selectedBaseModel={selectedBaseModel}
              selectedDeployedModel={selectedDeployedModel}
            />
          ),
          evaluationNode: (props) => (
            <EvaluationNode
              {...props}
              formData={formData}
              updateEvalTest={updateEvalTest}
              tests={tests}
            />
          ),
          deployNode: (props) => (
            <DeployNode
              {...props}
              formData={formData}
              updateField={updateField}
              tests={tests}
              selectedBaseModel={selectedBaseModel}
              selectedDeployedModel={selectedDeployedModel}
            />
          ),
        }}
        defaultViewport={{ x: 100, y: 100, zoom: 0.7 }}
        zoomOnScroll={false}
      />
    </div>
  );
};

export default FlowPipeline;
