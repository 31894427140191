import React from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { getLogMessage } from '../../../utils/TailorUtils';
import { modelStateMessage } from './modelStateConstants';

const ModelCard = ({
  model,
  handleSetSelectedModel,
  toggleDeployStatus,
  selfHostedServers,
  isSelected,
  index,
}) => {
  // ... (copy the modelStateMessage object from the original ModelList component)

  const findSelfHostedServerName = (id) => {
    const server = selfHostedServers.find((server) => server.id === id);
    return server ? server.server_name : 'Tromero';
  };

  return (
    <>
      {/* desktop */}
      <div
        aria-roledescription="button"
        key={model.model_id + ' ' + index}
        className={clsx(
          'flex items-start justify-between w-[98%] p-4 border rounded-md border-zinc-300 relative h-40 min-h-40 shadow-sm',
          isSelected ? 'bg-zinc-100 ring-2 ' : 'bg-white',
          'hidden md:block',
        )}
        onClick={() => handleSetSelectedModel(model)}
      >
        <div className="grid w-full grid-cols-6">
          {model.self_hosted &&
            (model.state === 'deployed' ||
              model.state === 'failed_undeploy') && (
              <div className="absolute bottom-1 left-1 bg-blue-200 text-xs py-1 px-2 rounded-md">
                Self Hosted:{' '}
                {findSelfHostedServerName(model.self_hosted_server_id)}
              </div>
            )}
          <div className="self-start text-lg max-w-full col-span-2 font-medium text-left break-words text-zinc-800 text-wrap md:self-center">
            <div className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
              {model.model_name}
            </div>
            <div className="text-sm font-light mt-1">
              Base Model: {model?.base_model_data?.display_name}
            </div>
            <div className="text-sm font-light line-clamp-3">
              Training Data: {getLogMessage(model)}
            </div>
          </div>
          <div className="flex items-start text-sm text-zinc-600 justify-self-center">
            {new Date(model.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
          <div className="flex items-start text-sm text-zinc-600 justify-self-center">
            {model.last_deployed_on
              ? new Date(model.last_deployed_on).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
              : null}
          </div>
          <div className="flex items-start text-sm text-zinc-600 justify-self-center">
            {model.last_used
              ? new Date(model.last_used).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
              : null}
          </div>
          {/* <div className="flex text-sm text-zinc-600 justify-self-end">
            <Switch.Group
              as="div"
              className="flex items-center absolute bottom-4 right-4"
            >
              <Switch
                checked={
                  model.state === 'deployed' ||
                  model.state === 'failed_undeploy'
                }
                onClick={(e) => toggleDeployStatus(e, model)}
                disabled={
                  model.state === 'deploying' || model.state === 'undeploying'
                }
                className={clsx(
                  model.state === 'deployed' ? 'bg-indigo-600' : 'bg-gray-400',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 self-end border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2  disabled:cursor-default disabled:border mb-3 disabled:border-gray-200',
                  model.state === 'failed_undeploy'
                    ? 'disabled:bg-indigo-300'
                    : 'disabled:bg-gray-100',
                )}
              >
                <span
                  aria-hidden="true"
                  className={clsx(
                    model.state === 'deployed' ||
                      model.state === 'failed_undeploy'
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out shadow-zinc-400',
                  )}
                />
              </Switch>
              <span className="absolute flex flex-col flex-grow text-right text-wrap bottom-4 right-4 self-end">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium leading-6 text-right text-gray-900"
                  passive
                >
                  {modelStateMessage[model.state]?.switchLabel}
                </Switch.Label>
                <Switch.Description
                  as="span"
                  className="text-sm text-left text-gray-500 self-end "
                >
                  {modelStateMessage[model.state]?.switchDescription}
                </Switch.Description>
              </span>
              
            </Switch.Group>
          </div> */}
          <Switch.Group
            as="div"
            className="flex flex-col absolute bottom-4 right-4"
          >
            <Switch
              checked={
                model.state === 'deployed' || model.state === 'failed_undeploy'
              }
              onClick={(e) => toggleDeployStatus(e, model)}
              disabled={
                model.state === 'deploying' || model.state === 'undeploying'
              }
              className={clsx(
                model.state === 'deployed' ? 'bg-indigo-600' : 'bg-gray-400',
                'self-end relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:cursor-default mb-3',
                model.state === 'failed_undeploy'
                  ? 'disabled:bg-indigo-300'
                  : 'disabled:bg-gray-100',
              )}
            >
              <span
                aria-hidden="true"
                className={clsx(
                  model.state === 'deployed' ||
                    model.state === 'failed_undeploy'
                    ? 'translate-x-5'
                    : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <span className="flex flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium leading-6 text-left text-gray-900 capitalize self-end"
                passive
              >
                {modelStateMessage[model.state]?.switchLabel}
              </Switch.Label>
              <Switch.Description
                as="span"
                className="text-sm text-left text-gray-500 self-end"
              >
                {modelStateMessage[model.state]?.switchDescription}
              </Switch.Description>
            </span>
          </Switch.Group>
        </div>
      </div>

      {/* mobile */}
      <div
        aria-roledescription="button"
        key={model.model_id}
        className={clsx(
          'flex flex-col w-[98%] px-4 py-2 border rounded-md border-zinc-300 min-h-44 relative',
          isSelected ? 'bg-zinc-100 ring-2 ' : 'bg-white',
          'block md:hidden',
        )}
        onClick={() => handleSetSelectedModel(model)}
      >
        {model.self_hosted &&
          (model.state === 'deployed' || model.state === 'failed_undeploy') && (
            <>
              <div className="absolute top-2 right-2 bg-blue-200 text-xs py-1 px-2 rounded-md max-w-[30%]">
                Self Hosted:
                <div className="text-right whitespace-nowrap overflow-hidden text-ellipsis w-full">
                  {findSelfHostedServerName(model.server_id)}
                </div>
              </div>
            </>
          )}
        <div className="font-medium text-left text-zinc-800 text-wrap h-full">
          <div
            className={clsx(
              'whitespace-nowrap overflow-hidden text-ellipsis w-full text-base',
              model.self_hosted &&
                (model.state === 'deployed' ||
                  model.state === 'failed_undeploy') &&
                '!w-[65%]',
            )}
          >
            {model.model_name}
          </div>
          <div className="text-xs font-light mt-1">
            Base Model: {model?.model_config?.base_model}
          </div>
          <div className="text-xs font-light flex-grow line-clamp-3 max-w-[19rem]">
            Training Data: {getLogMessage(model)}
          </div>{' '}
        </div>
        <div className="mt-2 text-xs">
          <div className="flex items-start text-zinc-600 justify-self-center md:items-center">
            Created on:{' '}
            {new Date(model.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
        </div>
        <div className="text-xs grow">
          <div className="flex items-start text-zinc-600 justify-self-center md:items-center">
            Last Used:{' '}
            {new Date(model.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
        </div>
        <Switch.Group
          as="div"
          className="flex flex-col absolute bottom-2 right-4"
        >
          <Switch
            checked={
              model.state === 'deployed' || model.state === 'failed_undeploy'
            }
            onClick={(e) => toggleDeployStatus(e, model)}
            disabled={
              model.state === 'deploying' || model.state === 'undeploying'
            }
            className={clsx(
              model.state === 'deployed' ? 'bg-indigo-600' : 'bg-gray-400',
              'self-end relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:cursor-default mb-1',
              model.state === 'failed_undeploy'
                ? 'disabled:bg-indigo-300'
                : 'disabled:bg-gray-100',
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                model.state === 'deployed' || model.state === 'failed_undeploy'
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
          <span className="flex flex-col">
            <Switch.Label
              as="span"
              className="text-sm font-medium leading-6 text-left text-gray-900 capitalize self-end"
              passive
            >
              {modelStateMessage[model.state]?.switchLabel}
            </Switch.Label>
            <Switch.Description
              as="span"
              className="text-sm text-left text-gray-500 self-end"
            >
              {modelStateMessage[model.state]?.switchDescription}
            </Switch.Description>
          </span>
        </Switch.Group>
      </div>
    </>
  );
};

export default React.memo(ModelCard);
