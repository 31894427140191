import React, { useState, Fragment, useEffect } from 'react';
import { Listbox, Transition, Switch } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { tests } from '../../Tailor/ModelEvaluations/MixEvalTests';

const MobilePipeline = ({
  formData,
  updateField,
  updateEvalTest,
  stepStyle,
  currentStep,
  baseModels,
  deployedModels,
  datasets,
  uniqueTags,
}) => {
  // Job Initialization
  const [jobName, setJobName] = useState(formData.jobName || '');
  const [modelAlias, setModelAlias] = useState(
    formData.modelAlias?.startsWith('alias_') ? formData.modelAlias : 'alias_',
  );

  // Model Selection
  const [selectedModelType, setSelectedModelType] = useState(
    formData.base_model ? 'base' : formData.deployed_model ? 'deployed' : '',
  );
  const [selectedBaseModel, setSelectedBaseModel] = useState(
    formData.base_model || '',
  );
  const [selectedDeployedModel, setSelectedDeployedModel] = useState(
    formData.deployed_model || '',
  );

  // Data Node
  const [selectedTags, setSelectedTags] = useState(formData.tags || []);
  const [selectedDataset, setSelectedDataset] = useState(
    Array.isArray(formData.dataset) ? formData.dataset : [],
  );
  const [logsThreshold, setLogsThreshold] = useState(
    formData.logsThreshold || '',
  );

  // Data Enhancements
  const [synthesizeLogs, setSynthesizeLogs] = useState(
    formData.synthetic || false,
  );
  const [improveLogs, setImproveLogs] = useState(formData.improved || false);

  // Evaluation Node
  const [evaluationTests, setEvaluationTests] = useState(
    formData.evalTests || Array(tests.length).fill(false),
  );
  const [improvementThresholds, setImprovementThresholds] = useState(
    formData.improvementThresholds || Array(tests.length).fill(''),
  );

  // Deployment Node
  const [epochs, setEpochs] = useState(formData.epochs || '');
  const [batchSize, setBatchSize] = useState(formData.batch_size || '');
  const [learningRate, setLearningRate] = useState(
    formData.learning_rate || '',
  );

  // Group models by family
  const groupedModels = (models) => {
    if (!models || models.length === 0) {
      return [];
    }

    const families = {
      Mistral: [],
      Mixtral: [],
      LLaMA: [],
      Gemma: [],
      Phi: [],
      Databricks: [],
      Others: [],
    };

    models.forEach((model) => {
      if (model.model_name.includes('mistral')) {
        families.Mistral.push(model);
      } else if (model.model_name.includes('mixtral')) {
        families.Mixtral.push(model);
      } else if (model.model_name.includes('llama')) {
        families.LLaMA.push(model);
      } else if (model.model_name.includes('gemma')) {
        families.Gemma.push(model);
      } else if (model.model_name.includes('phi')) {
        families.Phi.push(model);
      } else if (model.model_name.includes('dbrx')) {
        families.Databricks.push(model);
      } else {
        families.Others.push(model);
      }
    });

    return Object.keys(families).map((family) => ({
      family,
      models: families[family],
    }));
  };

  const getImageUrlForFamily = (family) => {
    switch (family) {
      case 'Mistral':
        return '/mistral.png';
      case 'Mixtral':
        return '/mistral.png';
      case 'LLaMA':
        return '/meta.png';
      case 'Gemma':
        return '/googleicon.png';
      case 'Phi':
        return '/microsoft.svg';
      case 'Databricks':
        return '/databricks.png';
      default:
        return null;
    }
  };

  // Update formData when local state changes
  useEffect(() => {
    updateField('jobName', jobName);
  }, [jobName]);

  useEffect(() => {
    updateField('modelAlias', modelAlias);
  }, [modelAlias]);

  useEffect(() => {
    updateField('tags', selectedTags);
  }, [selectedTags]);

  useEffect(() => {
    updateField('dataset', selectedDataset);
  }, [selectedDataset]);

  useEffect(() => {
    updateField('logsThreshold', logsThreshold);
  }, [logsThreshold]);

  useEffect(() => {
    updateField('synthetic', synthesizeLogs);
  }, [synthesizeLogs]);

  useEffect(() => {
    updateField('improved', improveLogs);
  }, [improveLogs]);

  useEffect(() => {
    updateField('evalTests', evaluationTests);
  }, [evaluationTests]);

  useEffect(() => {
    updateField('improvementThresholds', improvementThresholds);
  }, [improvementThresholds]);

  useEffect(() => {
    updateField('epochs', epochs);
  }, [epochs]);

  useEffect(() => {
    updateField('batch_size', batchSize);
  }, [batchSize]);

  useEffect(() => {
    updateField('learning_rate', learningRate);
  }, [learningRate]);

  // Handle input changes
  const handleAliasChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.startsWith('alias_')) {
      setModelAlias(inputValue);
    }
  };

  const handleModelSelection = (value, type) => {
    if (type === 'base') {
      setSelectedBaseModel(value);
      setSelectedModelType('base');
      setSelectedDeployedModel('');

      // Find the selected base model object
      const selectedBaseModelObj = baseModels.find(
        (model) => model.model_name === value,
      );

      if (selectedBaseModelObj) {
        // Update base_model and base_model_id in formData
        updateField('base_model', selectedBaseModelObj.model_name);
        updateField('base_model_id', selectedBaseModelObj.model_id);
      }

      // Clear deployed_model and model_id
      updateField('deployed_model', null);
      updateField('model_id', null);
    } else if (type === 'deployed') {
      setSelectedDeployedModel(value);
      setSelectedModelType('deployed');
      setSelectedBaseModel('');

      // Find the selected deployed model object
      const selectedDeployedModelObj = deployedModels.find(
        (model) => model.model_name === value,
      );

      if (selectedDeployedModelObj) {
        // Update deployed_model and model_id in formData
        updateField('deployed_model', selectedDeployedModelObj.model_name);
        updateField('model_id', selectedDeployedModelObj.model_id);
      }

      // Clear base_model and base_model_id
      updateField('base_model', null);
      updateField('base_model_id', null);
    }
  };

  const handleBlurLogsThreshold = () => {
    let value = parseInt(logsThreshold);
    if (isNaN(value)) value = 0;
    setLogsThreshold(value.toString());
  };

  const handleCheckboxChange = (index) => {
    const updatedTests = [...evaluationTests];
    updatedTests[index] = !updatedTests[index];
    setEvaluationTests(updatedTests);
  };

  const handleThresholdChange = (index, value) => {
    const updatedThresholds = [...improvementThresholds];
    updatedThresholds[index] = value;
    setImprovementThresholds(updatedThresholds);
  };

  const handleEpochsBlur = () => {
    if (!epochs) setEpochs('1');
  };

  const handleBatchSizeBlur = () => {
    if (!batchSize) setBatchSize('32');
  };

  const handleLearningRateBlur = () => {
    if (!learningRate) setLearningRate('0.0002');
  };

  return (
    <div className="p-4 space-y-4 w-full mx-auto bg-white shadow-lg rounded-lg font-dmSans">
      <h2 className="text-lg mb-4">Pipeline Configuration</h2>

      {/* Job Initialization */}
      <div className="bg-zinc-50 border p-4 rounded-md text-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-gray-700">Job Initialization</h3>
          <Tooltip
            content={
              <div className="w-48">
                Choose a name for your continuous training pipeline job. You
                won't be able to change this later.
              </div>
            }
            placement="left"
          >
            <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
          </Tooltip>
        </div>
        <div className="mb-4">
          <label className="block mb-2 ">Job Name</label>
          <input
            type="text"
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
            className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500 text-sm focus:ring-zinc-800 focus:border-zinc-800"
            placeholder="Enter job name"
          />
        </div>
        <div className="mb-4 ">
          <label className="block mb-2">Model Alias</label>
          <input
            type="text"
            value={modelAlias}
            onChange={handleAliasChange}
            className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500 text-sm focus:ring-zinc-800 focus:border-zinc-800"
            placeholder="Enter model alias (starts with 'alias_')"
          />
        </div>
      </div>

      {/* Model Selection */}
      <div className="bg-zinc-50 border p-4 rounded-md text-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-gray-700">Select a Model</h3>
          <Tooltip
            content={
              <div className="w-48">
                Choose a model to base this training job on. You can begin with
                a base model or one of your fine-tuned models.
              </div>
            }
            placement="left"
          >
            <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
          </Tooltip>
        </div>
        {/* Base Models */}
        <div className="mb-4">
          <label className="block mb-2">Select a Base Model</label>
          <Listbox
            value={selectedBaseModel}
            onChange={(value) => handleModelSelection(value, 'base')}
          >
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md border border-zinc-500 ">
                <span className="block truncate">
                  {selectedBaseModel || 'Select a base model'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="w-5 h-5 text-gray-400" />
                </span>
              </Listbox.Button>
              <Transition as={Fragment}>
                <Listbox.Options className="absolute w-full mt-1 bg-white border rounded-md shadow-lg  py-1 text-base z-10">
                  {groupedModels(baseModels)?.map((group) => (
                    <Fragment key={group.family}>
                      <div className="px-4 py-2 font-semibold text-gray-900 bg-gray-100">
                        <div className="flex flex-row items-center justify-start">
                          <img
                            src={getImageUrlForFamily(group.family)}
                            alt=""
                            className="w-auto h-5 mr-2"
                          />
                          {group.family}
                        </div>
                      </div>
                      {group.models.map((model) => (
                        <Listbox.Option
                          key={model.model_name}
                          value={model.model_name}
                          className={({ active }) =>
                            `cursor-pointer select-none relative py-2 pl-10 pr-4 ${
                              active ? 'bg-zinc-50 border' : 'text-gray-900'
                            }`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={
                                  selected ? 'font-semibold' : 'font-normal'
                                }
                              >
                                {model.display_name}
                              </span>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-zinc-600">
                                  <CheckIcon className="w-5 h-5" />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Fragment>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        <div className="flex items-center justify-center mb-4">or</div>

        {/* Deployed Models */}
        <div className="mb-4">
          <label className="block mb-2">Select a Fine-tuned Model</label>
          <Listbox
            value={selectedDeployedModel}
            onChange={(value) => handleModelSelection(value, 'deployed')}
          >
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md border border-zinc-500">
                <span className="block truncate">
                  {selectedDeployedModel || 'Select a fine-tuned model'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="w-5 h-5 text-gray-400" />
                </span>
              </Listbox.Button>
              <Transition as={Fragment}>
                <Listbox.Options className="absolute w-full mt-1 bg-white border rounded-md shadow-lg py-1 text-base z-10">
                  {deployedModels.map((model) => (
                    <Listbox.Option
                      key={model.model_name}
                      value={model.model_name}
                      className={({ active }) =>
                        `cursor-pointer select-none relative py-2 pl-10 pr-4 ${
                          active ? 'bg-zinc-50 border' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {model.model_name}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-zinc-600">
                              <CheckIcon className="w-5 h-5" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>

      {/* Data Node */}
      <div className="bg-zinc-50 border p-4 rounded-md text-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-gray-700">Data</h3>
          <Tooltip
            content={
              <div className="w-48">
                Choose the data you want to use for this training job. You can
                select tags to filter logs or datasets to use to train the
                model.
              </div>
            }
            placement="left"
          >
            <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
          </Tooltip>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Select Tags</label>
          <Listbox value={selectedTags} onChange={setSelectedTags} multiple>
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md border border-zinc-500">
                <span className="block truncate">
                  {selectedTags.length > 0
                    ? selectedTags.join(', ')
                    : 'Select tags'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="w-5 h-5 text-gray-400" />
                </span>
              </Listbox.Button>
              <Transition as={Fragment}>
                <Listbox.Options className="absolute w-full mt-1 bg-white border rounded-md shadow-lg  py-1 text-base z-10">
                  {uniqueTags.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      value={option}
                      className={({ active }) =>
                        `cursor-pointer select-none relative py-2 pl-10 pr-4 ${
                          active ? 'bg-zinc-50 border' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {option}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-zinc-600">
                              <CheckIcon className="w-5 h-5" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Select Dataset</label>
          <Listbox
            value={selectedDataset}
            onChange={setSelectedDataset}
            multiple
          >
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md border border-zinc-500">
                <span className="block truncate">
                  {selectedDataset.length > 0
                    ? selectedDataset.join(', ')
                    : 'Select datasets'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="w-5 h-5 text-gray-400" />
                </span>
              </Listbox.Button>
              <Transition as={Fragment}>
                <Listbox.Options className="absolute w-full mt-1 bg-white border rounded-md shadow-lg  py-1 text-base z-10">
                  {datasets.map((dataset) => (
                    <Listbox.Option
                      key={dataset.id}
                      value={dataset.name}
                      className={({ active }) =>
                        `cursor-pointer select-none relative py-2 pl-10 pr-4 ${
                          active ? 'bg-zinc-50 border' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {dataset.name}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-zinc-600">
                              <CheckIcon className="w-5 h-5" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div>
          <label className="block mb-2">
            Number of Logs (Training Threshold)
          </label>
          <input
            type="number"
            value={logsThreshold}
            onChange={(e) => setLogsThreshold(e.target.value)}
            onBlur={handleBlurLogsThreshold}
            className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500 text-sm focus:ring-zinc-800 focus:border-zinc-800"
            placeholder="Enter number of logs"
          />
        </div>
      </div>

      {/* Data Enhancements */}
      <div className="bg-zinc-50 border p-4 rounded-md text-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-gray-700">Data Enhancements</h3>
          <Tooltip
            content={
              <div className="w-48">
                Enhance the data by synthesizing logs or improving logs prior to
                training the model.
              </div>
            }
            placement="left"
          >
            <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
          </Tooltip>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={synthesizeLogs}
            onChange={(e) => setSynthesizeLogs(e.target.checked)}
            className="mr-2 rounded checked:bg-zinc-800 focus:bg-zinc-800 focus:ring-zinc-800"
          />
          <label>Synthesize Logs</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={improveLogs}
            onChange={(e) => setImproveLogs(e.target.checked)}
            className="mr-2 rounded checked:bg-zinc-800 focus:bg-zinc-800 focus:ring-zinc-800"
          />
          <label>Improve Logs</label>
        </div>
      </div>

      {/* Evaluation Node */}
      <div className="bg-zinc-50 border p-4 rounded-md text-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-gray-700">Evaluation</h3>
          <Tooltip
            content={
              <div className="w-48">
                Select the evaluation tests you want to run on the model. Custom
                evaluations will be coming soon.
              </div>
            }
            placement="left"
          >
            <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
          </Tooltip>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {tests.map((test, index) => (
            <div key={test.id} className="flex items-center">
              <input
                type="checkbox"
                checked={evaluationTests[index] || false}
                onChange={() => handleCheckboxChange(index)}
                className="mr-2 rounded checked:bg-zinc-800 focus:bg-zinc-800 focus:ring-zinc-800"
              />
              <label>{test.displayName}</label>
            </div>
          ))}
        </div>
      </div>

      {/* Deployment Node */}
      <div className="bg-zinc-50 border p-4 rounded-md text-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-gray-700">Deployment</h3>
          <Tooltip
            content={
              <div className="w-48">
                Define the conditions for deploying the model. You can set the
                improvement thresholds for each evaluation test and the training
                configurations.
              </div>
            }
            placement="left"
          >
            <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
          </Tooltip>
        </div>

        {/* Improvement Thresholds */}
        <div className="mb-6 text-sm">
          <label className="block mb-2">Improvement Thresholds (%)</label>
          {evaluationTests.map((isSelected, index) => {
            if (isSelected) {
              return (
                <div key={index} className="my-3">
                  <label className="block">{tests[index].displayName}</label>
                  <input
                    type="number"
                    value={improvementThresholds[index] || ''}
                    onChange={(e) =>
                      handleThresholdChange(index, e.target.value)
                    }
                    className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500  text-sm focus:ring-zinc-800 focus:border-zinc-800"
                    placeholder="Enter improvement threshold"
                  />
                </div>
              );
            }
            return null;
          })}
          {evaluationTests.every((isSelected) => !isSelected) && (
            <div className="italic text-zinc-500 border p-1 rounded">
              No selected evaluation tests
            </div>
          )}
        </div>

        {/* Training Configuration */}
        <div className="mb-4">
          <label className="block mb-2">Training Configuration</label>
          <div className="mb-2">
            <label className="block">Epochs</label>
            <input
              type="number"
              value={epochs}
              onChange={(e) => setEpochs(e.target.value)}
              onBlur={handleEpochsBlur}
              className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500 text-sm focus:ring-zinc-800 focus:border-zinc-800"
              placeholder="Enter number of epochs"
            />
          </div>
          <div className="mb-2">
            <label className="block">Batch Size</label>
            <input
              type="number"
              value={batchSize}
              onChange={(e) => setBatchSize(e.target.value)}
              onBlur={handleBatchSizeBlur}
              className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500 text-sm focus:ring-zinc-800 focus:border-zinc-800"
              placeholder="Enter batch size"
            />
          </div>
          <div>
            <label className="block">Learning Rate</label>
            <input
              type="number"
              value={learningRate}
              onChange={(e) => setLearningRate(e.target.value)}
              onBlur={handleLearningRateBlur}
              className="w-full rounded-lg bg-white py-2 px-3 shadow-md border border-zinc-500 text-sm focus:ring-zinc-800 focus:border-zinc-800"
              placeholder="Enter learning rate"
              step="0.0001"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePipeline;
