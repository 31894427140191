import React from 'react';
import { Link } from 'react-router-dom';

const getReadableStatusAndProgress = (status) => {
  switch (status) {
    case 'idle':
      return { name: 'Ready', progress: 5 };
    case 'evaluatingInitialModel':
      return { name: 'Evaluating Initial Model', progress: 10 };
    case 'initialModelEvaluationComplete':
      return { name: 'Initial Model Evaluated', progress: 20 };
    case 'checkingSyntheticDataRequirement':
      return { name: 'Checking Synthetic Data', progress: 30 };
    case 'syntheticDataReady':
      return { name: 'Synthetic Data Ready', progress: 40 };
    case 'trainingModel':
      return { name: 'Training Model', progress: 50 };
    case 'trainingComplete':
      return { name: 'Training Complete', progress: 70 };
    case 'evaluatingTrainedModel':
      return { name: 'Evaluating Trained Model', progress: 80 };
    case 'trainedModelEvaluationComplete':
      return { name: 'Trained Model Evaluated', progress: 90 };
    case 'failedToDeployNewModel':
      return { name: 'Failed to Deploy Model', progress: 95 };
    default:
      return { name: 'Unknown Status', progress: 0 };
  }
};

const ActivePipelinesProgress = ({ activePipelines }) => {
  const pipelines = activePipelines?.filter((pipeline) => pipeline.active);

  return (
    <div className="flex space-x-4 overflow-x-auto py-4 px-2">
      {pipelines.map((pipeline, index) => {
        // Get the readable status and progress for each pipeline state
        const { name: readableStatus, progress } = getReadableStatusAndProgress(
          pipeline.state,
        );

        return (
          <Link
            key={index}
            to={{
              pathname: '/tailor/pipeline',
              state: { selectedPipeline: pipeline }, // Pass the selected pipeline as state
            }}
            className="w-64 p-4 border rounded-lg bg-zinc-50 flex-shrink-0 hover:border-zinc-800/50"
          >
            <div className="h-0 w-0 rounded-full "></div>
            <h3 className="text-base font-medium mb-2">{pipeline.job_name}</h3>

            {/* Status and Progress Bar */}
            <p className="text-sm text-zinc-600 mb-2">
              <span className="font-medium text-zinc-800">Status:</span>{' '}
              {readableStatus || 'Unknown'}
            </p>

            {/* Progress Bar */}
            <div className="w-full bg-white shadow-inner rounded-full h-1.5 mb-4">
              <div
                className="wavyslow opacity-30 h-1.5 rounded-full"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-sm text-zinc-600 mb-1">
              <span className="font-medium text-zinc-800">Logs Threshold:</span>{' '}
              {pipeline.training_triggers.number_of_logs}
            </p>
          </Link>
        );
      })}
    </div>
  );
};

export default ActivePipelinesProgress;
