import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineLock, MdOutlineLockOpen } from 'react-icons/md';
import clsx from 'clsx';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';

const CustomImageDetails = () => {
  const [dockerhubPath, setDockerhubPath] = useState({ value: '', error: '' });
  const [dockerAccessToken, setDockerAccessToken] = useState({
    value: '',
    error: '',
  });
  const [portsToOpenError, setPortsToOpenError] = useState('');
  const [httpPortsToOpen, setHttpPortsToOpen] = useState({
    value: '',
    error: '',
  });
  const [tcpPortsToOpen, setTcpPortsToOpen] = useState({
    value: '',
    error: '',
  });
  const [hasSshAccess, setHasSshAccess] = useState(false);
  const [templateVisibility, setTemplateVisibility] = useState('public');
  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [showEnvironmentVariables, setShowEnvironmentVariables] =
    useState(false);
  const [environmentalVariablesError, setEnvironmentalVariablesError] =
    useState('');
  const [startCommand, setStartCommand] = useState({ value: '', error: '' });
  const [postStartCommand, setPostStartCommand] = useState({
    value: '',
    error: '',
  });

  /**
   * Validate the form fields
   * @returns {boolean} isValid
   * @returns {boolean} isValid.isValid - Form is valid or not
   * @returns {string} isValid.error - Error message
   */
  const validateForm = () => {
    let isValid = true;

    // const dockerhubPathRegex =
    //   /^[a-z0-9]+(?:[._-]?[a-z0-9]+)*\/[a-z0-9]+(?:[._-]?[a-z0-9]+)*:[a-z0-9]+(?:[._-]?[a-z0-9]+)*$/i;
    const dockerhubPathRegex = /.*/;

    // Validate Dockerhub Path
    if (!dockerhubPath.value) {
      setDockerhubPath((prev) => ({
        ...prev,
        error: 'Dockerhub Path is required',
      }));
      isValid = false;
    } else if (!dockerhubPathRegex.test(dockerhubPath.value)) {
      setDockerhubPath((prev) => ({
        ...prev,
        error: 'Invalid Dockerhub Path',
      }));
      isValid = false;
    } else {
      setDockerhubPath((prev) => ({ ...prev, error: '' }));
    }

    const portsToOpenRegex =
      /^(?:(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])(?:-(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]))?,)*(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])(?:-(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]))?$/;
    // Validate HTTP Ports
    if (
      httpPortsToOpen.value &&
      !portsToOpenRegex.test(httpPortsToOpen.value)
    ) {
      setHttpPortsToOpen((prev) => ({
        ...prev,
        error: 'Invalid HTTP Ports',
      }));
      isValid = false;
    } else {
      setHttpPortsToOpen((prev) => ({ ...prev, error: '' }));
    }

    // Validate TCP Ports
    if (tcpPortsToOpen.value && !portsToOpenRegex.test(tcpPortsToOpen.value)) {
      setTcpPortsToOpen((prev) => ({
        ...prev,
        error: 'Invalid TCP Ports',
      }));
      isValid = false;
    } else {
      setTcpPortsToOpen((prev) => ({ ...prev, error: '' }));
    }

    // Validate Ports to Open (HTTP or TCP)
    if (!httpPortsToOpen.value && !tcpPortsToOpen.value) {
      setPortsToOpenError(
        'You must specify at least 1 HTTP or TCP Port to Open',
      );
      isValid = false;
    } else {
      setPortsToOpenError('');
    }

    // Validate Docker Access Token if Template is Private
    if (templateVisibility === 'private' && !dockerAccessToken.value) {
      setDockerAccessToken((prev) => ({
        ...prev,
        error: 'Docker Access Token is required',
      }));
      isValid = false;
    } else {
      setDockerAccessToken((prev) => ({ ...prev, error: '' }));
    }

    // Validate Environment Variables
    const trimmedEnvVars = trimEnvironmentVariables();
    if (showEnvironmentVariables && trimmedEnvVars.length > 0) {
      const invalidEnvVars = trimmedEnvVars.filter(
        (envVar) => !envVar.key || !envVar.value,
      );
      if (invalidEnvVars.length) {
        setEnvironmentalVariablesError(
          'Missing Key or Value in Environment Variables',
        );
        isValid = false;
      }
    }

    if (startCommand.value) {
      try {
        const trimmedValue = startCommand.value.trim();
        if (
          trimmedValue[0] !== '[' ||
          trimmedValue[trimmedValue.length - 1] !== ']'
        ) {
          throw new Error(
            'Post Start Command should be an array. e.g. ["echo", "hello"]',
          );
        }
        const startCommandArray = JSON.parse(trimmedValue);
        if (!Array.isArray(startCommandArray)) {
          throw new Error(
            'Start Command should be an array. e.g. ["echo", "hello"]',
          );
        }
        startCommandArray.forEach((command) => {
          if (typeof command !== 'string') {
            throw new Error(
              'Start Command should be an array of strings. e.g. ["echo", "hello"]',
            );
          }
        });
        setStartCommand((prev) => ({ ...prev, error: '' }));
      } catch (error) {
        let errorText = error.message;
        if (error.message.startsWith('Unexpected token')) {
          errorText = 'Invalid format - You should use double quotes';
        }
        setStartCommand((prev) => ({
          ...prev,
          error: errorText,
        }));
        isValid = false;
      }
    }

    if (postStartCommand.value) {
      try {
        const trimmedValue = postStartCommand.value.trim();
        if (
          trimmedValue[0] !== '[' ||
          trimmedValue[trimmedValue.length - 1] !== ']'
        ) {
          throw new Error(
            'Post Start Command should be an array. e.g. ["echo", "hello"]',
          );
        }
        const postStartCommandArray = JSON.parse(trimmedValue);
        if (!Array.isArray(postStartCommandArray)) {
          throw new Error(
            'Post Start Command should be an array. e.g. ["echo", "hello"]',
          );
        }
        postStartCommandArray.forEach((command) => {
          if (typeof command !== 'string') {
            throw new Error(
              'Post Start Command should be an array of strings. e.g. ["echo", "hello"]',
            );
          }
        });
        setPostStartCommand((prev) => ({ ...prev, error: '' }));
      } catch (error) {
        let errorText = error.message;
        if (error.message.startsWith('Unexpected token')) {
          errorText = 'Invalid format - You should use double quotes';
        }
        setPostStartCommand((prev) => ({
          ...prev,
          error: errorText,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  /**
   * Trim empty environment variables (if both key and value are empty)
   * @returns {Array} trimmedEnvVars
   */
  const trimEnvironmentVariables = () => {
    const trimmedEnvVars = environmentVariables.filter(
      (envVar) => envVar.key || envVar.value,
    );
    trimmedEnvVars.forEach((envVar) => {
      if (!envVar.key && !envVar.value) {
        const index = trimmedEnvVars.indexOf(envVar);
        trimmedEnvVars.splice(index, 1);
      }
    });
    setEnvironmentVariables(trimmedEnvVars);
    if (trimmedEnvVars.length === 0) {
      setShowEnvironmentVariables(false);
    }
    return trimmedEnvVars;
  };

  const handleContinue = () => {
    if (showEnvironmentVariables) {
      trimEnvironmentVariables();
    }
    const formIsValid = validateForm();
    if (formIsValid) {
      const customImageDetails = {
        dockerhubPath: dockerhubPath.value,
        httpPortsToOpen: httpPortsToOpen.value,
        tcpPortsToOpen: tcpPortsToOpen.value,
        hasSshAccess,
      };

      if (templateVisibility === 'private') {
        customImageDetails.dockerAccessToken = dockerAccessToken.value;
      } else if (templateVisibility === 'public') {
        customImageDetails.dockerAccessToken = '';
      }

      if (environmentVariables.length > 0) {
        customImageDetails.environmentVariables = environmentVariables;
      }

      if (startCommand.value) {
        const startCommandArray = JSON.parse(startCommand.value);
        customImageDetails.startCommand = startCommandArray;
      } else {
        customImageDetails.startCommand = '';
      }

      if (postStartCommand.value) {
        const postStartCommandArray = JSON.parse(postStartCommand.value);
        customImageDetails.postStartCommand = postStartCommandArray;
      }

      // Save the custom image details to sessionStorage
      sessionStorage.setItem(
        'customImageDetails',
        JSON.stringify(customImageDetails),
      );

      const nextSection = document.getElementById('tabs-container');
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleHttpPortsToOpenChange = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    setHttpPortsToOpen({ ...httpPortsToOpen, value });
  };

  const handleTcpPortsToOpenChange = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    setTcpPortsToOpen({ ...tcpPortsToOpen, value });
  };

  const handleShowEnvironmentVariables = () => {
    setShowEnvironmentVariables((prev) => !prev);
    setEnvironmentalVariablesError('');
    if (!showEnvironmentVariables) {
      setEnvironmentVariables([{ key: '', value: '' }]);
    }
  };

  const handleSetTemplateVisibility = (visibility) => {
    setTemplateVisibility(visibility);
    setDockerAccessToken({ ...dockerAccessToken, error: '' });
    setDockerhubPath({ ...dockerhubPath, error: '' });
    setHttpPortsToOpen({ ...httpPortsToOpen, error: '' });
    setTcpPortsToOpen({ ...tcpPortsToOpen, error: '' });
    setPortsToOpenError('');
    setEnvironmentalVariablesError('');
    setStartCommand({ ...startCommand, error: '' });
    setPostStartCommand({ ...postStartCommand, error: '' });
  };

  const tooltipText = () => {
    return (
      <div className="text-xs text-gray-500">
        <p>
          A <b>batch job</b> runs a finite workload to completion, such as data
          processing tasks like generating reports or performing database
          migrations. Once the process is completed, the instance will cease to
          be rented. It is up to the user to write any results to an external
          file store.
        </p>
        <p className="mt-2">
          A <b>service</b> provides a long-running, stable endpoint to expose a
          set of services/applications, such as a web server or an API backend
          that needs to be accessible continuously
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col w-full my-12 rounded-[12px] wavyslow shadow-3xinner ">
        <div className="absolute inset-0 hidden rounded-[12px] bg-gray-800/80 dark:block"></div>
        <div className="z-10 h-full m-1 bg-white/90 rounded-[8px] dark:bg-gray-800/70">
          <div className="flex flex-row items-center justify-between">
            <h1 className="relative -top-5 left-7 text-2xl italic tracking-wide text-slate-800 font-extralight px-0.5 backdrop-blur-lg dark:text-gray-100 rounded-full">
              Custom Image Details
            </h1>
          </div>
          <div className="flex flex-col w-full p-4">
            <div className="flex flex-col mb-5">
              <label
                className="text-sm text-slate-800 dark:text-gray-100"
                htmlFor="templateVisibility"
              >
                Template Visibility
              </label>
              <div className="flex items-center mt-2">
                <button
                  className={`${
                    templateVisibility === 'public'
                      ? 'bg-slate-700 text-white'
                      : 'bg-white text-slate-800'
                  } w-24 h-8 text-sm font-medium text-center rounded-l-lg border border-slate-700 dark:border-slate-500 dark:text-gray-200 dark:bg-gray-800 focus:ring-0 focus:outline-none focus:ring-slate-300 flex-center`}
                  onClick={() => handleSetTemplateVisibility('public')}
                >
                  <MdOutlineLockOpen className="inline-block w-4 h-4 mr-1" />{' '}
                  Public
                </button>
                <button
                  className={`${
                    templateVisibility === 'private'
                      ? 'bg-slate-700 text-white'
                      : 'bg-white text-slate-800'
                  } w-24 h-8 text-sm font-medium text-center rounded-r-lg border border-slate-700 dark:border-slate-500 dark:text-gray-200 dark:bg-gray-800 focus:ring-0 focus:outline-none focus:ring-slate-300 flex-center`}
                  onClick={() => handleSetTemplateVisibility('private')}
                >
                  <MdOutlineLock className="inline-block w-4 h-4 mr-1" />{' '}
                  Private
                </button>
              </div>
            </div>
            <div className="relative flex flex-col w-full mb-5">
              <label
                className="text-sm text-slate-800 dark:text-gray-100"
                htmlFor="dockerhub"
              >
                <Link
                  to="https://hub.docker.com/"
                  className="hover:underline"
                  target="_blank"
                >
                  Path
                </Link>
                <span className="block text-xs text-gray-500 md:ml-2 md:inline">
                  (format: username/repo:tag)
                </span>
              </label>
              <input
                id="dockerhub"
                type="text"
                autoCapitalize="off"
                className={clsx(
                  'w-full p-2 mt-2 whitespace-normal border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent',
                  dockerhubPath.error ? 'border-red-500' : 'border-gray-300',
                )}
                value={dockerhubPath.value}
                onChange={(e) =>
                  setDockerhubPath({
                    ...dockerhubPath,
                    value: e.target.value.trim(),
                  })
                }
                onFocus={() =>
                  setDockerhubPath({ ...dockerhubPath, error: '' })
                }
              />
              {dockerhubPath.error && (
                <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                  {dockerhubPath.error}
                </div>
              )}
            </div>
            {templateVisibility === 'private' && (
              <div className="relative flex flex-col w-full mb-5">
                <label
                  className="text-sm text-slate-800 dark:text-gray-100"
                  htmlFor="accessToken"
                >
                  Access Token
                </label>
                <input
                  id="accessToken"
                  type="text"
                  autoCapitalize="off"
                  className={clsx(
                    'w-full p-2 mt-2 whitespace-normal border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent',
                    dockerAccessToken.error
                      ? 'border-red-500'
                      : 'border-gray-300',
                  )}
                  value={dockerAccessToken.value}
                  onChange={(e) =>
                    setDockerAccessToken({
                      ...dockerAccessToken,
                      value: e.target.value.trim(),
                    })
                  }
                  onFocus={() =>
                    setDockerAccessToken({ ...dockerAccessToken, error: '' })
                  }
                />
                {dockerAccessToken.error && (
                  <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                    {dockerAccessToken.error}
                  </div>
                )}
              </div>
            )}
            <div className="relative w-full mb-5">
              <div className="flex flex-col md:flex-row gap-x-4 gap-y-4 ">
                <div className={clsx('relative flex flex-col w-full')}>
                  <div className="flex items-stretch">
                    <label
                      className="text-sm text-slate-800 dark:text-gray-100"
                      htmlFor="httpPorts"
                    >
                      HTTP Ports to Open
                      <span className="block text-xs text-gray-500 md:ml-2 md:inline">
                        (comma separated or range of ports, e.g.,
                        80,443,8000-8080)
                      </span>
                    </label>
                  </div>
                  <input
                    id="httpPorts"
                    type="text"
                    autoCapitalize="off"
                    className={clsx(
                      'w-full p-2 mt-2 whitespace-normal border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent',
                      httpPortsToOpen.error || portsToOpenError
                        ? 'border-red-500'
                        : 'border-gray-300',
                    )}
                    value={httpPortsToOpen.value}
                    onChange={handleHttpPortsToOpenChange}
                    onFocus={() => {
                      setHttpPortsToOpen({ ...httpPortsToOpen, error: '' });
                      setPortsToOpenError('');
                    }}
                  />
                  {httpPortsToOpen.error && (
                    <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                      {httpPortsToOpen.error}
                    </div>
                  )}
                </div>
                <div className={clsx('relative flex flex-col w-full')}>
                  <div className="flex items-stretch">
                    <label
                      className="text-sm text-slate-800 dark:text-gray-100"
                      htmlFor="tcpPorts"
                    >
                      TCP Ports to Open
                      <span className="block text-xs text-gray-500 md:ml-2 md:inline">
                        (comma separated or range of ports, e.g.,
                        21,22,25,80,443 or 1024-49151)
                      </span>
                    </label>
                  </div>
                  <input
                    id="tcpPorts"
                    type="text"
                    autoCapitalize="off"
                    className={clsx(
                      'w-full p-2 mt-2 whitespace-normal border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent',
                      tcpPortsToOpen.error || portsToOpenError
                        ? 'border-red-500'
                        : 'border-gray-300',
                    )}
                    value={tcpPortsToOpen.value}
                    onChange={handleTcpPortsToOpenChange}
                    onFocus={() => {
                      setTcpPortsToOpen({ ...tcpPortsToOpen, error: '' });
                      setPortsToOpenError('');
                    }}
                  />
                  {tcpPortsToOpen.error && (
                    <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                      {tcpPortsToOpen.error}
                    </div>
                  )}
                </div>
              </div>
              {portsToOpenError && (
                <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                  {portsToOpenError}
                </div>
              )}
            </div>
            <div className="relative flex flex-col w-full mb-5">
              <label
                className="text-sm text-slate-800 dark:text-gray-100"
                htmlFor="startCommand"
              >
                Start Command
                <div className="text-xs text-gray-500 md:ml-2 md:inline">
                  (Command to run after the image is loaded){' '}
                </div>
              </label>
              <div className="text-xs text-gray-500 flex flex-wrap">
                <span className="mr-2">
                  Should be an array of strings and use double quotes e.g.,
                </span>
                <span>["hello", "/usr/bin/python3", "app.py"]</span>
              </div>
              <input
                id="startCommand"
                type="text"
                autoCapitalize="off"
                className={clsx(
                  'w-full p-2 mt-2 whitespace-normal border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent',
                  startCommand.error ? 'border-red-500' : 'border-gray-300',
                )}
                value={startCommand.value}
                onChange={(e) =>
                  setStartCommand({
                    ...startCommand,
                    value: e.target.value,
                  })
                }
                onFocus={() => setStartCommand({ ...startCommand, error: '' })}
              />
              {startCommand.error && (
                <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                  {startCommand.error}
                </div>
              )}
            </div>

            <div className="relative flex flex-col w-full mb-5">
              <label
                className="text-sm text-slate-800 dark:text-gray-100"
                htmlFor="postStartCommand"
              >
                Post Start Command
                <div className="text-xs text-gray-500 md:ml-2 md:inline">
                  (Command to run after the start command){' '}
                </div>
              </label>
              <div className="text-xs text-gray-500 flex flex-wrap">
                <span className="mr-2">
                  Should be an array of strings and use double quotes e.g.,
                </span>
                <span>["echo", "hello"]</span>
              </div>
              <input
                id="postStartCommand"
                type="text"
                autoCapitalize="off"
                className={clsx(
                  'w-full p-2 mt-2 whitespace-normal border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent',
                  postStartCommand.error ? 'border-red-500' : 'border-gray-300',
                )}
                value={postStartCommand.value}
                onChange={(e) =>
                  setPostStartCommand({
                    ...postStartCommand,
                    value: e.target.value,
                  })
                }
                onFocus={() =>
                  setPostStartCommand({ ...postStartCommand, error: '' })
                }
              />
              {postStartCommand.error && (
                <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                  {postStartCommand.error}
                </div>
              )}
            </div>

            <div className="flex flex-col mb-5">
              <label
                className="text-sm text-slate-800 dark:text-gray-100"
                htmlFor="hasSshAccess"
              >
                <span className="flex items-center gap-2">
                  Batch Job or Service?
                  <Tooltip
                    content={tooltipText()}
                    arrow={false}
                    style="light"
                    placement="right-start"
                    className="max-w-xl shadow border border-zinc-400 z-50"
                  >
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </Tooltip>
                  <span className="block text-xs text-gray-500 md:ml-2 md:inline">
                    (Defaults to Service)
                  </span>
                </span>
              </label>
              <div>
                <input
                  id="hasSshAccess"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded-md text-slate-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent"
                  checked={hasSshAccess}
                  onChange={() => setHasSshAccess((prev) => !prev)}
                />
                <label
                  className="ml-2 text-sm text-slate-800 dark:text-gray-100"
                  htmlFor="hasSshAccess"
                >
                  My image runs as a batch job
                </label>
              </div>
            </div>

            <div className="flex items-center mb-2">
              <button
                className="text-sm text-slate-800 dark:text-gray-100 flex items-center"
                onClick={handleShowEnvironmentVariables}
              >
                <span className="mr-2">Environment Variables</span>
                <span>
                  {showEnvironmentVariables ? (
                    <ChevronUpIcon className="w-4 h-4" />
                  ) : (
                    <ChevronDownIcon className="w-4 h-4" />
                  )}
                </span>
              </button>
            </div>
            {showEnvironmentVariables && (
              <span className="text-xs text-gray-500">
                (Key-Value pairs for environment variables, they will be passed
                to the container and then removed from our system after the
                image is built)
              </span>
            )}
            {showEnvironmentVariables && (
              <>
                <div className={clsx('relative flex flex-col w-full mb-4')}>
                  <div className="flex flex-col mt-2 gap-y-3">
                    <div className="relative gap-y-4 md:gap-y-2 flex flex-col">
                      {environmentVariables.map((envVar, index) => (
                        <div
                          key={index}
                          className="flex flex-col md:flex-row items-center gap-x-4 gap-y-1"
                        >
                          <div className="w-full flex items-center">
                            <input
                              type="text"
                              autoCapitalize="off"
                              className={clsx(
                                'w-10/12 md:w-full  p-2 border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent placeholder:text-zinc-300',
                                environmentalVariablesError && !envVar.key
                                  ? 'border-red-500'
                                  : 'border-gray-300',
                              )}
                              placeholder="Key"
                              value={envVar.key}
                              onChange={(e) => {
                                const updatedEnvVars = [
                                  ...environmentVariables,
                                ];
                                updatedEnvVars[index].key =
                                  e.target.value.trim();
                                setEnvironmentVariables(updatedEnvVars);
                              }}
                              onFocus={() => {
                                setEnvironmentalVariablesError('');
                              }}
                            />
                            <div className="md:hidden mx-2">:</div>
                          </div>
                          <div className="hidden md:block">:</div>
                          <div className="w-full text-right md:text-left flex justify-end items-center gap-x-2">
                            <input
                              type="text"
                              autoCapitalize="off"
                              className={clsx(
                                'grow md:w-full p-2 border rounded-md dark:bg-gray-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent placeholder:text-zinc-300',
                                environmentalVariablesError && !envVar.value
                                  ? 'border-red-500'
                                  : 'border-gray-300',
                              )}
                              value={envVar.value}
                              placeholder="Value"
                              onChange={(e) => {
                                const updatedEnvVars = [
                                  ...environmentVariables,
                                ];
                                updatedEnvVars[index].value =
                                  e.target.value.trim();
                                setEnvironmentVariables(updatedEnvVars);
                              }}
                              onFocus={() => {
                                setEnvironmentalVariablesError('');
                              }}
                            />
                            <button
                              className="
                            w-8 h-8 text-slate-800 border rounded-lg hover:bg-slate-100 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:border-slate-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-slate-500 dark:focus:ring-slate-800 dark:bg-gray-800 flex-center"
                              onClick={() => {
                                const updatedEnvVars = [
                                  ...environmentVariables,
                                ];
                                updatedEnvVars.splice(index, 1);
                                setEnvironmentVariables(updatedEnvVars);
                              }}
                            >
                              <TrashIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                      {environmentalVariablesError && (
                        <div className="absolute text-xs text-red-500 -bottom-4 md:left-1 right-1">
                          {environmentalVariablesError}
                        </div>
                      )}
                    </div>
                    <button
                      className=" w-24 h-8 text-sm font-medium text-center bg-white border rounded-lg hover:bg-slate-100 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:border-slate-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-slate-500 dark:focus:ring-slate-800 dark:bg-gray-800 flex-center mt-2

                      "
                      onClick={() => {
                        setEnvironmentalVariablesError('');
                        setEnvironmentVariables([
                          ...environmentVariables,
                          { key: '', value: '' },
                        ]);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-row flex-wrap-reverse items-end justify-start my-2 md:flex-nowrap gap-y-3">
              <button
                className="z-20 flex items-center justify-center w-full text-sm font-medium text-center bg-white border rounded-lg disabled:hover:bg-white md:mr-8 md:w-36 text-slate-800 border-slate-700 hover:bg-slate-100 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:border-slate-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-slate-500 dark:focus:ring-slate-800 dark:bg-gray-800 h-9 md:ml-0"
                onClick={handleContinue}
              >
                Save & Continue
              </button>
              <Link
                to={'/templates/custom-image-guide'}
                target="_blank"
                className="pb-1 text-xs hover:underline text-slate-800 md:ml-4 underline-offset-4 hover:cursor-pointer dark:text-gray-200 dark:hover:text-white dark:underline-offset-4 dark:hover:underline-offset-4"
              >
                Setup Guide
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomImageDetails;
