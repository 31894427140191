import { useState, useEffect, useCallback } from 'react';
import { useUser } from '../UserContext';

const useBaseModels = () => {
  const [baseModels, setBaseModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { customAxios } = useUser();

  useEffect(() => {
    const fetchBaseModels = async () => {
      try {
        const response = await customAxios.get('tailor/v1/base_models');

        for (const model of response.data.models) {
          if (!model.model_type) {
            model.model_type = 'language_model';
          }
          if (model.model_type === 'language_model') {
            if (model.model_name.includes('mistral')) {
              model.min_logs_required = 1_000;
              model.good_number_of_logs_required = 2_000;
              model.excellent_number_of_logs_required = 5_000;
            } else if (model.model_name.includes('mixtral')) {
              model.min_logs_required = 2_000;
              model.good_number_of_logs_required = 4_000;
              model.excellent_number_of_logs_required = 8_000;
            } else if (
              model.model_name.includes('llama') ||
              model.model_name.includes('gemma') ||
              model.model_name.includes('dbrx') ||
              model.model_name.includes('phi')
            ) {
              model.min_logs_required = 8_000;
              model.good_number_of_logs_required = 12_000;
              model.excellent_number_of_logs_required = 18_000;
            }
          }
        }
        setBaseModels(response.data.models);
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error('Error fetching base models:', error);
        }
        setError(error);
        setBaseModels([
          {
            id: 1,
            display_name: 'mistral-7b',
            model_name: 'mistral-7b-instruct-v0.3',
            available_for_inference: true,
            available_for_fine_tuning: true,
            model_type: 'language_model',
          },
          {
            id: 2,
            display_name: 'mixtral-8x7b',
            model_name: 'mixtral-8x7b-instruct-v0.1',
            available_for_inference: true,
            available_for_fine_tuning: true,
            model_type: 'language_model',
          },
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchBaseModels();
  }, [customAxios]);

  const getFilteredModels = useCallback(
    (filter) => {
      return baseModels.filter(filter);
    },
    [baseModels],
  );

  return {
    loading,
    error,
    getInferenceBaseModels: useCallback(
      () => getFilteredModels((model) => model.available_for_inference),
      [getFilteredModels],
    ),
    getFineTuningBaseModels: useCallback(
      () => getFilteredModels((model) => model.available_for_finetuning),
      [getFilteredModels],
    ),
    getPlaygroundBaseModels: useCallback(
      () =>
        getFilteredModels(
          (model) =>
            model.available_for_inference &&
            model.model_type === 'language_model',
        ),
      [getFilteredModels],
    ),
    getAllBaseModels: useCallback(() => baseModels, [baseModels]),
  };
};

export default useBaseModels;
