import React, { useCallback, useState, useEffect } from 'react';
import FlowPipeline from './Pipeline/FlowPipeline';
import MobilePipeline from './Pipeline/MobilePipeline';
import { Modal } from 'flowbite-react';
import { Switch } from '@headlessui/react';
import { useUser } from '../../UserContext';
import { useParams, useLocation } from 'react-router-dom';
import { tests } from '../Tailor/ModelEvaluations/MixEvalTests';
import {
  fetchBaseModels,
  fetchDeployList,
  fetchLogs,
  retrieveDatasets,
} from '../Tailor/Pipeline/apiRequests';
import { toast } from 'react-hot-toast';

const TailorPipeline = () => {
  const { customAxios, user } = useUser();
  const { id } = useParams();
  const location = useLocation();
  const selectedPipeline = location.state?.selectedPipeline || {};
  const [pipelines, setPipelines] = useState([]);
  const [selectedPipelineIndex, setSelectedPipelineIndex] = useState(0);
  const [formData, setFormData] = useState(pipelines[0]);
  // const [formData, setFormData] = useState(mockPipelines[0]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deployedModels, setDeployedModels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [uniqueTags, setUniqueTags] = useState([]);
  const [baseModels, setBaseModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  // Fetch base models
  useEffect(() => {
    fetchBaseModels(customAxios, setBaseModels);
  }, [customAxios]);

  // Fetch deployed models
  useEffect(() => {
    fetchDeployList(
      customAxios,
      setDeployedModels,
      setSelectedModel,
      setError,
      id,
      setLoading,
    );
  }, [customAxios, id]);

  // Fetch logs
  useEffect(() => {
    fetchLogs(customAxios, setUniqueTags, setLoading);
  }, [customAxios]);

  // Fetch datasets
  useEffect(() => {
    retrieveDatasets(customAxios, setDatasets, setLoading);
  }, [customAxios]);

  useEffect(() => {
    const fetchUserPipelines = async () => {
      try {
        const response = await customAxios.get(
          '/tailor/v1/continuous-training-pipeline',
        );

        if (response.status === 200) {
          const fetchedPipelines = response.data.pipelines;

          const transformedPipelines = fetchedPipelines.map((pipeline) => {
            // Transform the pipeline data into the format expected by the components

            // Ensure tags is always an array
            let tagsArray = [];
            if (pipeline.tags) {
              if (Array.isArray(pipeline.tags)) {
                tagsArray = pipeline.tags;
              } else if (typeof pipeline.tags === 'string') {
                tagsArray = pipeline.tags.split(',').map((tag) => tag.trim());
              }
            }

            // Ensure dataset is an array of dataset names
            let datasetArray = [];
            if (pipeline.dataset) {
              datasetArray = pipeline.dataset.map((ds) => ds.name);
            }

            // Prepare evalTests and improvementThresholds
            const evalTestsArray = tests.map((test) => {
              return pipeline.eval_types?.includes(test.nameToBackend) || false;
            });

            const improvementThresholdsArray = tests.map((test) => {
              const testName = test.nameToBackend;
              if (
                pipeline.evaluations_with_thresholds?.mix_eval?.[testName] !==
                undefined
              ) {
                return pipeline.evaluations_with_thresholds.mix_eval[testName];
              } else if (
                pipeline.evaluations_with_thresholds?.needlehaystack?.scores !==
                undefined
              ) {
                return pipeline.evaluations_with_thresholds.needlehaystack
                  .scores;
              } else {
                return 0;
              }
            });

            return {
              jobName: pipeline.job_name || '',
              jobDateTime: pipeline.job_date_time || '',
              deployed_model: pipeline.deployed_model || '',
              model_id: pipeline.model_id || '',
              base_model: pipeline.base_model || '',
              base_model_id: pipeline.base_model_id || '',
              tags: tagsArray,
              dataset: datasetArray,
              synthetic: pipeline.data_improvements?.synthesize_logs || false,
              improved: pipeline.data_improvements?.improve_logs || false,
              logsThreshold: pipeline.training_triggers?.number_of_logs || '',
              evalTests: evalTestsArray,
              improvementThresholds: improvementThresholdsArray,
              deploy: pipeline.deploy || false,
              active: pipeline.active || false,
              modelAlias: pipeline.model_alias || '',
              epochs: pipeline.training_config?.epochs || '',
              batch_size: pipeline.training_config?.batch_size || '',
              learning_rate: pipeline.training_config?.learning_rate || '',
            };
          });

          //  console.log('Transformed Pipelines:', transformedPipelines);

          setPipelines(transformedPipelines);
          setLoading(false);

          // Set the initial formData to the first pipeline if available
          if (transformedPipelines.length > 0) {
            setFormData(transformedPipelines[0]);
          } else {
            setFormData(initialFormData);
          }
        } else {
          toast.error('Failed to fetch pipelines');
          // console.log(
          //   'Failed to fetch pipelines:',
          //   response.status,
          //   response.data,
          // );
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching pipelines:', error);
        toast.error('An error occurred while fetching pipelines');
        setLoading(false);
      }
    };

    fetchUserPipelines();
  }, [customAxios]);

  const data = {
    jobName: '',
    jobDateTime: '',
    deployed_model: '',
    model_id: '',
    base_model: '',
    base_model_id: '',
    tags: [],
    dataset: [],
    synthetic: false,
    improved: false,
    logsThreshold: '',
    evalTests: Array(tests.length).fill(false),
    improvementThresholds: [],
    deploy: false,
    active: false,
    modelAlias: '',
    epochs: '',
    batch_size: '',
    learning_rate: '',
  };

  const transformFormDataToAPIFormat = (
    formData,
    user,
    baseModels, // array of base models
    deployedModels, // array of deployed models
    datasets,
    modelAliasId,
  ) => {
    // Prepare dataset IDs
    const datasetIds = formData.dataset
      .map((datasetName) => {
        const matchedDataset = datasets.find((d) => d.name === datasetName);
        return matchedDataset ? matchedDataset.id : null; // Return ID if found, null otherwise
      })
      .filter(Boolean); // Remove any null entries

    // Prepare tags
    const tags = formData.tags.join(', ') || null;

    // Prepare evaluations_with_thresholds
    const evaluationsWithThresholds = {
      mix_eval: {},
      needlehaystack: {},
    };

    // Map over evalTests and check which tests belong to mix_eval or needlehaystack
    formData.evalTests.forEach((isSelected, index) => {
      if (isSelected) {
        const test = tests[index]; // Access the test by index
        const threshold = formData.improvementThresholds[index] || 0; // Get threshold for this test

        // Sort the test into mix_eval or needlehaystack
        if (test.nameToBackend === 'needlehaystack') {
          evaluationsWithThresholds.needlehaystack['scores'] = threshold;
        } else {
          evaluationsWithThresholds.mix_eval[test.nameToBackend] = threshold;
        }
      }
    });

    // Filter out empty categories
    if (Object.keys(evaluationsWithThresholds.needlehaystack).length === 0) {
      delete evaluationsWithThresholds.needlehaystack;
    }

    // Prepare eval_types
    const evalTypes = [];
    if (Object.keys(evaluationsWithThresholds.mix_eval).length > 0) {
      evalTypes.push('mix_eval');
    }
    // Ensure evaluations_with_thresholds.needlehaystack is always initialized as an object
    if (
      evaluationsWithThresholds.needlehaystack &&
      Object.keys(evaluationsWithThresholds.needlehaystack).length > 0
    ) {
      evalTypes.push('needlehaystack');
    }

    // Construct final data object, including both base_model_id and model_id
    return {
      user_id: user.id,
      job_name: formData.jobName,
      model_alias: formData.modelAlias,
      model_alias_id: modelAliasId,
      base_model_id: formData.base_model_id, // Will be null if not set
      model_id: formData.model_id, // Will be null if not set
      dataset_id: datasetIds.length > 0 ? datasetIds[0] : null,
      tags: tags || 'test_tags',
      data_improvements: {
        synthesize_logs: formData.synthetic,
        improve_logs: formData.improved,
      },
      evaluations_with_thresholds: evaluationsWithThresholds,
      eval_types: JSON.stringify(evalTypes), // Convert eval types to a JSON string
      training_triggers: {
        number_of_logs: formData.logsThreshold || 0,
      },
      training_config: {
        epochs: formData.epochs || '',
        batch_size: formData.batch_size || '',
        learning_rate: formData.learning_rate || '',
      },
    };
  };

  const startNewPipeline = () => {
    setFormData(data);
    setIsCreatingNew(true);
    setSelectedPipelineIndex(null);
    setCurrentStep(0);
    setOpenModal(true);
  };

  const selectPipeline = (pipeline, index) => {
    setFormData(pipeline);
    setSelectedPipelineIndex(index);
    setIsCreatingNew(false);
  };

  const updateField = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    validateStepCompletion(field, value);
  };

  const updateEvalTest = (index, isSelected) => {
    const updatedEvalTests = [...formData.evalTests];
    updatedEvalTests[index] = isSelected; // Set the test at the correct index
    updateField('evalTests', updatedEvalTests); // Update the formData globally
  };

  const handleSubmit = async () => {
    try {
      // Find the base model ID
      const baseModel = formData.base_model
        ? baseModels.find((model) => model.model_name === formData.base_model)
        : null;

      // Find the deployed model ID
      const selectedModel = formData.deployed_model
        ? deployedModels.find(
            (model) => model.model_name === formData.deployed_model,
          )
        : null;

      const payload = {
        alias_name: formData.modelAlias,
        model_name: formData.deployed_model || formData.base_model,
      };

      // First API call to post to /alias
      const aliasResponse = await customAxios.post(
        '/tailor/v1/aliases/create',
        payload,
      );

      if (aliasResponse.status === 201 || aliasResponse.status === 200) {
        toast.success('Alias created successfully');
        // If alias creation was successful, proceed to create the pipeline

        const modelAliasId = aliasResponse.data.alias.alias_id;

        // Transform the form data into the required API format
        const transformedData = transformFormDataToAPIFormat(
          formData, // Current form data
          user, // Current user information
          baseModel, // Base model object, contains id
          selectedModel, // Selected fine-tuned model object, contains id
          datasets, // Selected datasets
          modelAliasId,
        );

        const pipelineResponse = await customAxios.post(
          '/tailor/v1/continuous-training-pipeline',
          transformedData,
        );

        if (
          pipelineResponse.status === 201 ||
          pipelineResponse.status === 200
        ) {
          // If the pipeline creation is successful, update the UI
          if (isCreatingNew) {
            // Add new pipeline to the list
            setPipelines((prevPipelines) => [...prevPipelines, formData]);
            setIsCreatingNew(false);
          }

          toast.success('Pipeline successfully created');
        } else {
          toast.error('Failed to create pipeline. Please try again.');
        }
      } else {
        toast.error('Failed to create alias. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  // step related functions
  const togglePipelineActive = (index) => {
    const updatedPipelines = [...pipelines];
    updatedPipelines[index].active = !updatedPipelines[index].active;
    setPipelines(updatedPipelines);
  };

  const handleCancel = () => {
    setFormData(data);
  };

  const validateStepCompletion = (field, value) => {
    if (currentStep === 0 && formData.jobName && formData.jobDateTime) {
      setCurrentStep(1);
    } else if (currentStep === 1 && formData.model) {
      setCurrentStep(2);
    }
  };

  const isStepCompleted = (stepIndex) => stepIndex < currentStep;

  const isStepActive = (stepIndex) => stepIndex === currentStep;

  const stepStyle = (stepIndex) => {
    if (isStepActive(stepIndex)) {
      return 'border border-green-500';
    }
    if (isStepCompleted(stepIndex)) {
      return 'border border-gray-500'; // Completed steps are gray
    }
    return 'border border-gray-300 bg-gray-100 ';
  };
  // step related functions

  return (
    <div className="md:h-screen bg-zinc-50 grid-background-small font-dmSans flex flex-col">
      <header className="pt-10 lg:pt-0 bg-zinc-50">
        <div className="flex justify-between h-16 p-4 text-xl font-medium text-zinc-800">
          Continuous Training Pipelines
          <div></div>
        </div>
        <hr className="border-t border-zinc-300" />
      </header>

      <div className="w-full flex flex-col md:flex-row p-4 space-y-4 md:space-x-4 md:space-y-0 overflow-hidden -mb-8 pr-12 z-0 h-auto md:max-h-[380px]">
        <div className="h-full w-full rounded border z-10  bg-zinc-50 col-span-1 md:max-h-[350px]">
          {/* Display formData in a structured format */}
          <h3 className=" mb-2 text-zinc-800 px-4 pt-2">Pipeline Summary</h3>
          <div className="flex px-4 text-zinc-500 font-light text-sm">
            Defines an automated development plan for continuous training.
          </div>
          <hr className="my-2  border-gray-300" />
          <div className="px-4 py-4">
            {formData && Object.keys(formData).length > 0 ? (
              <table className="table-auto w-full text-left text-zinc-600 text-xs font-light">
                <tbody>
                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Job Name:</td>
                    <td className="col-span-2">{formData?.jobName || '-'}</td>
                  </tr>
                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Model Alias:</td>
                    <td className="col-span-2">
                      {formData?.modelAlias || '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Model:</td>
                    <td className="col-span-2">
                      {formData?.base_model
                        ? `${formData?.base_model}`
                        : formData?.deployed_model
                          ? `${formData?.deployed_model}`
                          : '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Tags:</td>
                    <td className="col-span-2 overflow-hidden text-ellipsis line-clamp-1">
                      {formData?.tags.length ? formData?.tags.join(', ') : '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Dataset:</td>
                    <td className="col-span-2 overflow-hidden text-ellipsis line-clamp-1">
                      {formData?.dataset.length
                        ? formData?.dataset.join(', ')
                        : '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Logs Threshold:</td>
                    <td className="col-span-2">
                      {formData?.logsThreshold || '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Improve Logs:</td>
                    <td className="col-span-2">
                      {formData?.improved ? 'Yes' : 'No'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Synthesize Logs:</td>
                    <td className="col-span-2">
                      {formData?.synthetic ? 'Yes' : 'No'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Evaluation Tests:</td>
                    <td className="col-span-2 overflow-hidden text-ellipsis line-clamp-3">
                      {formData?.evalTests
                        .map((isChecked, index) => {
                          if (isChecked && tests[index]) {
                            const testName = tests[index].displayName;
                            const threshold =
                              formData?.improvementThresholds &&
                              formData?.improvementThresholds[index]
                                ? formData?.improvementThresholds[index]
                                : '-';
                            return `${testName} (${threshold}%)`;
                          }
                          return null;
                        })
                        .filter(Boolean)
                        .join(', ') || '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Active:</td>
                    <td className="col-span-2">
                      {formData?.active ? 'Yes' : 'No'}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="text-center text-zinc-500 text-sm pb-24 flex items-center justify-center  h-48">
                <div className="border border-dashed p-6">
                  No data available. Create a new pipeline to get started.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full rounded border bg-zinc-50 col-span-1  h-full  overflow-hidden">
          <header className="pt-10 lg:pt-0 bg-zinc-50">
            <div className="flex justify-between h-16 px-4 pt-2 text-zinc-800">
              All Pipelines
              <div>
                <div>
                  <button
                    className="bg-zinc-900 text-white text-sm rounded px-3 py-2 mr-4 mt-2"
                    onClick={startNewPipeline}
                  >
                    + New pipeline
                  </button>
                </div>
              </div>
            </div>
          </header>
          <hr className="my-1  border-gray-300" />
          <div className="flex flex-col p-4 pb-24 gap-4 h-full font-dmSans overflow-y-scroll">
            {pipelines.map((pipeline, index) => (
              <div
                onClick={() => selectPipeline(pipeline, index)}
                key={index}
                className={`w-full flex flex-col bg-white rounded shadow p-4 ${
                  selectedPipelineIndex === index
                    ? 'border-[1.5px] border-zinc-500'
                    : ''
                }`}
              >
                <div className="flex flex-row items-center justify-between text-zinc-800 mb-2">
                  <div
                    className={`${
                      selectedPipelineIndex === index
                        ? 'text-zinc-900 font-medium'
                        : ''
                    } text-zinc-600 font-light`}
                  >
                    {pipeline.jobName}
                  </div>

                  <div
                    className="flex items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <label className="text-xs">
                      {pipeline.active ? 'Active' : 'Off'}
                    </label>

                    <Switch
                      checked={pipeline.active}
                      onChange={() => togglePipelineActive(index)}
                      className={`${
                        pipeline.active ? 'bg-zinc-900' : 'bg-zinc-400'
                      } relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none scale-50`}
                    >
                      <span className="sr-only">Toggle Active</span>
                      <span
                        className={`${
                          pipeline.active ? 'translate-x-6' : 'translate-x-0'
                        } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                  </div>
                </div>
                <hr className="mb-2 border-zinc-50" />
                <div className="text-zinc-600 font-light text-sm">
                  Using base model {pipeline.model}, data which includes{' '}
                  {pipeline.tags.join(', ')} tags, enhanced with{' '}
                  {pipeline.synthetic ? 'synthetic' : 'real'} logs. Evaluates
                  with{' '}
                  {pipeline.evalTests.filter((test) => test).length || 'no'}{' '}
                  tests, with an improvement threshold of{' '}
                  {pipeline.improvementThreshold}%. Set to deploy status{' '}
                  {pipeline.active ? 'active' : 'manual'}.
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full h-full mt-4 hidden md:block">
        <FlowPipeline
          formData={formData}
          updateField={updateField}
          updateEvalTest={updateEvalTest}
          handleSubmit={handleSubmit}
          stepStyle={stepStyle}
          currentStep={currentStep}
          baseModels={baseModels}
          deployedModels={deployedModels}
          datasets={datasets}
          uniqueTags={uniqueTags}
        />
      </div>
      {/* <div className="absolute bottom-8 text-sm">
        <button
          onClick={handleSubmit}
          className="ml-6  py-2 px-3 bg-zinc-900 text-white rounded border border-zinc-900"
        >
          Activate pipeline
        </button>
        <button
          onClick={handleCancel}
          className="  ml-6 py-2 px-3 bg-zinc-50/50 border border-red-900 text-red-900 rounded"
        >
          Cancel
        </button>
      </div> */}
      <Modal
        className="font-dmSans overflow-x-hidden "
        size={'8xl'}
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header className="shadow-sm ">
          <span className="text-base font-normal">
            New Continuous Training Pipeline
          </span>
          <div className="h-full w-full rounded border z-10 bg-zinc-50 col-span-4 sm:max-h-[250px] mt-3">
            <div className="flex px-4 text-zinc-500 font-normal text-sm my-2">
              Defines an automated development plan for continuous training.
            </div>
            <hr className="my-2 border-gray-300" />
            <div className="px-4 mb-2">
              <table className="table-auto w-full text-left text-zinc-600 text-xs font-light">
                <tbody>
                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Job Name:</td>
                    <td className="col-span-2">{formData?.jobName || '-'}</td>
                  </tr>
                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Model Alias:</td>
                    <td className="col-span-2">
                      {formData?.modelAlias || '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Model:</td>
                    <td className="col-span-2">
                      {formData?.base_model
                        ? `${formData?.base_model}`
                        : formData?.deployed_model
                          ? `${formData?.deployed_model}`
                          : '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Tags:</td>
                    <td className="col-span-2 overflow-hidden text-ellipsis line-clamp-1">
                      {formData?.tags.length ? formData.tags.join(', ') : '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Dataset:</td>
                    <td className="col-span-2 overflow-hidden text-ellipsis line-clamp-1">
                      {formData?.dataset.length
                        ? formData?.dataset.join(', ')
                        : '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Logs Threshold:</td>
                    <td className="col-span-2">
                      {formData?.logsThreshold || '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Improve Logs:</td>
                    <td className="col-span-2">
                      {formData?.improved ? 'Yes' : 'No'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Synthesize Logs:</td>
                    <td className="col-span-2">
                      {formData?.synthetic ? 'Yes' : 'No'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Evaluation Tests:</td>
                    <td className="col-span-2 overflow-hidden text-ellipsis line-clamp-2">
                      {formData?.evalTests
                        .map((isChecked, index) => {
                          if (isChecked && tests[index]) {
                            const testName = tests[index].displayName;
                            const threshold =
                              formData?.improvementThresholds &&
                              formData?.improvementThresholds[index]
                                ? formData?.improvementThresholds[index]
                                : '-';
                            return `${testName} (${threshold}%)`;
                          }
                          return null;
                        })
                        .filter(Boolean)
                        .join(', ') || '-'}
                    </td>
                  </tr>

                  <tr className="grid grid-cols-3 gap-4">
                    <td className="col-span-1">Active:</td>
                    <td className="col-span-2">
                      {formData?.active ? 'Yes' : 'No'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="w-auto h-full overflow-x-hidden grid-background-small border rounded-lg md:-mt-24 pb-6">
            {/* FlowPipeline: Show on larger than md screens */}
            <div className="hidden md:block">
              <FlowPipeline
                className=""
                formData={formData}
                updateField={updateField}
                updateEvalTest={updateEvalTest}
                handleSubmit={handleSubmit}
                stepStyle={stepStyle}
                currentStep={currentStep}
                baseModels={baseModels}
                deployedModels={deployedModels}
                datasets={datasets}
                uniqueTags={uniqueTags}
              />
            </div>

            {/* MobilePipeline: Show on smaller than md screens */}
            <div className="block md:hidden">
              <MobilePipeline
                className=""
                formData={formData}
                updateField={updateField}
                updateEvalTest={updateEvalTest}
                handleSubmit={handleSubmit}
                stepStyle={stepStyle}
                currentStep={currentStep}
                baseModels={baseModels}
                deployedModels={deployedModels}
                datasets={datasets}
                uniqueTags={uniqueTags}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {' '}
          <div className="w-full">
            <div className="text-sm flex justify-end">
              <button
                onClick={handleSubmit}
                className="py-2 px-3 bg-zinc-900 text-white rounded border border-zinc-900"
              >
                Activate pipeline
              </button>
              <button
                onClick={handleCancel}
                className="ml-2 py-2 px-3 bg-zinc-50/50 border border-red-900 text-red-900 rounded "
              >
                Clear
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TailorPipeline;
