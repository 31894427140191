export const modelStateMessage = {
  start_training: {
    switchLabel: 'Training Starting',
    switchDescription: 'This model is starting the training process',
  },
  failed_training: {
    switchLabel: 'Failed',
    switchDescription: 'This model cannot be deployed',
  },
  deploying: {
    switchLabel: 'Deploying',
    switchDescription: 'This model is being deployed',
  },
  deployed: {
    switchLabel: 'Deployed',
    switchDescription: 'This model is deployed',
  },
  undeploying: {
    switchLabel: 'Undeploying',
    switchDescription: 'This model is being un-deployed',
  },
  training: {
    switchLabel: 'Training',
    switchDescription: 'This model is still training',
  },
  failed: {
    switchLabel: 'Failed',
    switchDescription: 'This model cannot be deployed',
  },
  dormant: {
    switchLabel: 'Ready to Deploy',
    switchDescription: 'Click to deploy',
  },
  failed_deploy: {
    switchLabel: 'Failed to deploy',
    switchDescription: 'Please try again',
  },
  failed_undeploy: {
    switchLabel: 'Failed to undeploy',
    switchDescription: 'This model could be un-deployed at the moment',
  },
};
