import { useCallback, useMemo } from 'react';

const useGetCodeSnippet = ({
  conversation,
  model,
  finalParameters,
  isSplitView,
}) => {
  const messagesString = useCallback((conversation) => {
    if (!conversation.length) {
      return '[]';
    }
    const stringLines = conversation.map((line) => {
      let stringLine = JSON.stringify(line);
      return `\t\t${stringLine},\n`;
    });

    return `[\n${stringLines.join('')}\t]`;
  }, []);

  const parametersString = useCallback((finalParameters, selectedLanguage) => {
    if (!finalParameters) {
      return '';
    }
    const stringLines = Object.entries(finalParameters).map(([key, value]) => {
      let stringLine;
      switch (selectedLanguage) {
        case 'python':
          stringLine = `\t${key}=${value},\n`;
          break;
        case 'javascript':
          stringLine = `\t${key}: ${value},\n`;
          break;
        case 'curl':
          stringLine = `\n\t"${key}": ${value},`;
          break;
      }
      return stringLine;
    });
    return `${stringLines.join('')}`;
  }, []);

  const snippets = useMemo(
    () => ({
      python: `
import os
from tromero import Tromero

client = Tromero(tromero_key=os.getenv('TROMERO_KEY'))

response = client.chat.completions.create(
\tmodel="${model}",
\tmessages=${messagesString(conversation)},
${parametersString(finalParameters, 'python')}
${isSplitView ? '' : '\tstream=True'}
)`,
      javascript: `
import Tromero from 'tromero'

const client = new Tromero({ tromeroKey: process.env.TROMERO_KEY });

const response = await client.chat.completions.create({
\tmodel: "${model}",
\tmessages: ${messagesString(conversation)},\n
${parametersString(finalParameters, 'javascript')}
${isSplitView ? '' : '\tstream: true'}
});
`,
      curl: `
curl -X POST https://api.tromero.com/v1/chat/completions \\
-H "Content-Type: application/json" \\
-H "X-API-KEY: YOU_TROMERO_KEY" \\
-d '{
\t"model": "${model}",
\t"messages": ${messagesString(conversation)},${parametersString(finalParameters, 'curl')}
${isSplitView ? '' : '\t"stream": true'}
}'`,
    }),
    [
      model,
      conversation,
      finalParameters,
      messagesString,
      parametersString,
      isSplitView,
    ],
  );

  return snippets;
};

export default useGetCodeSnippet;
