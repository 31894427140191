import { Fragment, useCallback, useEffect, useState } from 'react';
import { useUser } from '@/UserContext';
import NewAliasModal from './ModelAliasing/NewAliasModal';
import { Tooltip } from 'flowbite-react';
import { Popover, Transition } from '@headlessui/react';
import { Dialog, Transition as DialogTransition } from '@headlessui/react';
import { mockAliases } from './ModelAliasing/mockAliases';
import toast from 'react-hot-toast';

const TailorModelAliasing = () => {
  const { customAxios } = useUser();
  const [isAliasModalOpen, setIsAliasModalOpen] = useState(false);
  const [aliasObject, setAliasObject] = useState(null);
  const [aliasList, setAliasList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [aliasToDelete, setAliasToDelete] = useState(null);
  const [showRemoveModelConfirmation, setShowRemoveModelConfirmation] =
    useState(false);
  const [aliasToRemoveModel, setAliasToRemoveModel] = useState(null);

  const handleOpenAliasModal = () => {
    setIsAliasModalOpen(true);
  };

  const fetchAliases = useCallback(async () => {
    try {
      const response = await customAxios.get('tailor/v1/aliases');
      const { aliases } = response.data;

      for (const alias of aliases) {
        if (!alias.updated_at) {
          alias.updated_at = alias.created_at;
        }
      }

      const sortedAliases = aliases.sort((a, b) => b.updated_at - a.updated_at);

      setAliasList(sortedAliases.length ? sortedAliases : []);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to fetch aliases', {
        id: 'fetch-aliases-error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAliases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditAlias = (aliasObject) => {
    setAliasObject(aliasObject);
    setIsAliasModalOpen(true);
  };

  const handleDeleteAlias = (aliasObject) => {
    setAliasToDelete(aliasObject);
    setShowDeleteConfirmation(true);
  };

  const handleRemoveLinkedModel = (aliasObject) => {
    setAliasToRemoveModel(aliasObject);
    setShowRemoveModelConfirmation(true);
  };

  const confirmDeleteAlias = async () => {
    if (aliasToDelete) {
      try {
        await customAxios.delete(
          `/tailor/v1/aliases/delete/${aliasToDelete.alias_name}`,
        );
        setAliasList(
          aliasList.filter((a) => a.alias_name !== aliasToDelete.alias_name),
        );
        toast.success('Alias deleted successfully', {
          id: 'delete-alias-success',
        });
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        if (error.code === 'alias.in_use') {
          toast.error(
            'Failed to delete: Alias is currently attached to a model. ',
            {
              id: 'delete-alias-error',
            },
          );
        } else {
          toast.error('Failed to delete alias', {
            id: 'delete-alias-error',
          });
        }
      } finally {
        setShowDeleteConfirmation(false);
        setAliasToDelete(null);
      }
    }
  };

  const confirmRemoveLinkedModel = async () => {
    if (aliasToRemoveModel) {
      const payload = {
        alias_name: aliasToRemoveModel.alias_name,
        model_name: null,
      };

      try {
        await customAxios.put(`/tailor/v1/aliases/set/model`, payload);
        let newAliasList = aliasList;
        newAliasList = newAliasList.map((a) =>
          a.alias_name === aliasToRemoveModel.alias_name
            ? { ...a, model: null, base_model: null }
            : a,
        );
        setAliasList(newAliasList);
        toast.success('Model unlinked successfully', {
          id: 'unlink-model-success',
        });
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        toast.error('Failed to unlink model', {
          id: 'unlink-model-error',
        });
      } finally {
        setShowRemoveModelConfirmation(false);
        setAliasToRemoveModel(null);
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans">
      <header>
        <div
          className="flex justify-between h-16 p-4 text-xl font-medium text-zinc-800"
          role="banner"
        >
          Model Aliasing
          <div className="flex items-center gap-6">
            <Tooltip
              arrow={false}
              placement="bottom-end"
              style={'light'}
              content="
                    Create an alias to simplify model deployment. Assign a custom name to a specific model version, allowing you to switch versions easily without modifying your source code. Use this alias in the completion endpoint via the CLI or wrapper.
                    "
              className="shadow-md shadow-black/25 z-50 max-w-64"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-zinc-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                />
              </svg>
            </Tooltip>

            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center border rounded-md shadow md:mr-2 bg-zinc-100 hover:bg-zinc-200 focus:ring-0 focus:outline-none focus:ring-indigo-300 md:justify-evenly text-zinc-800"
              onClick={handleOpenAliasModal}
            >
              Create new alias
            </button>
          </div>
        </div>
        <hr className="border-t border-zinc-300" />
      </header>
      <div className="flex flex-col gap-4 p-4">
        {aliasList.map((alias) => (
          <div
            key={alias.alias_id}
            className="bg-white rounded-lg shadow-md p-4"
          >
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center relative">
              <div className="flex-grow">
                <h3 className="text-lg font-semibold">{alias.alias_name}</h3>
                <div className="flex flex-col text-sm text-gray-600">
                  <p>
                    Created:{' '}
                    {new Date(alias?.created_at).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                  <p>
                    Updated:{' '}
                    {new Date(alias?.updated_at).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                </div>
              </div>
              <div className="absolute right-0 top-0">
                <Popover className="relative mt-2 sm:mt-0">
                  {() => (
                    <>
                      <Popover.Button className="flex items-center justify-center w-8 h-8 text-gray-400 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open options</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                        </svg>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <div className="py-1">
                            <button
                              onClick={() => handleEditAlias(alias)}
                              className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                            >
                              Edit Alias
                            </button>
                            <button
                              onClick={() => handleRemoveLinkedModel(alias)}
                              className="block w-full px-4 py-2 text-sm text-left text-gray-700 disabled:opacity-50"
                              disabled={
                                !(
                                  alias?.model?.model_name ||
                                  alias?.base_model?.model_name
                                )
                              }
                            >
                              Remove Linked Model
                            </button>
                            <button
                              onClick={() => handleDeleteAlias(alias)}
                              className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                            >
                              Delete Alias
                            </button>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
            {/* grid layout  */}
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <h4 className="text-md font-medium">Current Model Attached:</h4>
                <p className="text-sm text-gray-600">
                  {alias?.model
                    ? alias.model.model_name
                    : alias?.base_model
                      ? alias.base_model.model_name
                      : '-'}
                </p>
                <p className="text-sm text-gray-600">
                  {alias?.model?.model_version ? (
                    <>Version: {alias?.model?.model_version}</>
                  ) : (
                    ''
                  )}
                </p>
              </div>
              <div>
                {alias.managed_by_pipeline && (
                  <>
                    <h4 className="text-md font-medium">
                      Managed By Pipeline:
                    </h4>
                    <p className="text-sm text-gray-600">
                      {alias?.managed_by_pipeline}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <NewAliasModal
        isOpen={isAliasModalOpen}
        onClose={() => {
          setIsAliasModalOpen(false);
          setTimeout(() => {
            setAliasObject(null);
          }, 300);
        }}
        aliasObject={aliasObject}
        aliasList={aliasList}
        setAliasList={setAliasList}
      />

      {/* Delete Confirmation Dialog */}
      <Transition appear show={showDeleteConfirmation} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setShowDeleteConfirmation(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Confirm Deletion
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete this alias? Any deployed
                    code using this alias in a completion will stop working.
                  </p>
                  <p className="mt-2 text-sm text-gray-500">
                    If you have set a fallback model, it will be used.
                    Otherwise, the endpoint will not produce a response.
                  </p>
                  <p className="mt-2 text-sm text-gray-500">
                    You can only delete an alias that is{' '}
                    <strong>not currently attached to a model.</strong>
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-red-600"
                    onClick={confirmDeleteAlias}
                    disabled={aliasToDelete?.managedByPipeline}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={() => setShowDeleteConfirmation(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Remove Linked Model Confirmation Dialog */}
      <Transition appear show={showRemoveModelConfirmation} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setShowRemoveModelConfirmation(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Confirm Remove Linked Model
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to remove the linked model from this
                    alias? Any call to this alias in the wrapper or CLI will
                    return an error until a new model is linked.
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={confirmRemoveLinkedModel}
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={() => setShowRemoveModelConfirmation(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default TailorModelAliasing;
