import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  Fragment,
  useRef,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  TrashIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

// Components
import TokenUsageChart from './Deploy/TokenUsageChart';
import { useUser } from '../../UserContext';
import Spinner from '../Spinner';
import DeleteModelWaringModal from './Deploy/DeleteModelWaringModal';
import UndeployWarningModal from './Deploy/UndeployWarningModal';
import DeployInstructions from './Deploy/DeployInstructions';
import SelfHostedOptionsModal from './Deploy/SelfHostedOptionsModal';
import ThreeDotsOptions from './Deploy/ThreeDotsOptions';
import DeleteModelDropdown from './Deploy/DeleteModelDropdown';
import ModelCard from './Deploy/ModelCard';
import BaseModelCard from './Deploy/BaseModelCard';
import { Link } from 'react-router-dom';
import { Tooltip } from 'flowbite-react';
import useBaseModels from '../../Hooks/useBaseModels';

const TailorDeploy = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Replace state variables with URL params
  const sortBy = searchParams.get('sortBy') || 'state';
  const sortOrder = searchParams.get('sortOrder') || 'asc';
  const filterBaseModel = searchParams.get('baseModel') || '';
  const filterDeployStatus = searchParams.get('deployStatus') || '';
  const searchTerm = searchParams.get('search') || '';

  const [deployList, setDeployList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [containerHeight, setContainerHeight] = useState('h-0');
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showUndeployWarning, setShowUndeployWarning] = useState(false);
  const [showDeployInstructions, setShowDeployInstructions] = useState(false);
  const [originalDeployList, setOriginalDeployList] = useState([]);
  const [showSelfHostedOptionsModal, setShowSelfHostedOptionsModal] =
    useState(false);
  const [selfHostedServers, setSelfHostedServers] = useState([]);
  const [serverToUseForDeployment, setServerToUseForDeployment] = useState();
  const { customAxios, user } = useUser();
  const [modelNotFound, setModelNotFound] = useState(false);
  const [modelToDeploy, setModelToDeploy] = useState(null);
  const [error, setError] = useState(null);
  const [baseModels, setBaseModels] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  const modelListRef = useRef(null);

  const baseModelOptions = [
    { id: 'all', name: 'All Base Models' },
    { id: 'llama', name: 'LLaMA' },
    { id: 'mistral', name: 'Mistral' },
    { id: 'mixtral', name: 'Mixtral' },
  ];

  const deployStatusOptions = [
    { id: 'all', name: 'All Deploy Statuses' },
    { id: 'deployed', name: 'Deployed' },
    { id: 'not_deployed', name: 'Not Deployed' },
  ];

  const {
    getPlaygroundBaseModels,
    loading: baseModelsLoading,
    error: baseModelsError,
  } = useBaseModels();

  const originalSort = useCallback((models) => {
    return models.sort((a, b) => {
      const stateOrder = [
        'deployed',
        'deploying',
        'dormant',
        'training',
        'failed',
        'failed_deploy',
        'failed_undeploy',
        'start_training',
        'failed_training',
      ];
      const stateIndexA = stateOrder.indexOf(a.state);
      const stateIndexB = stateOrder.indexOf(b.state);
      if (stateIndexA !== stateIndexB) {
        return stateIndexA - stateIndexB;
      }
      return b.created_at_unix - a.created_at_unix;
    });
  }, []);

  const fetchDeployList = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await customAxios.get('tailor/v1/models');
      let models = response?.data?.message;
      models = models.filter(
        (model) =>
          [
            'dormant',
            'deployed',
            'failed_deploy',
            'undeploying',
            'deploying',
            'failed_undeploy',
          ].includes(model.state) &&
          model.base_model_data.available_for_inference,
      );
      setOriginalDeployList(models);
      setDeployList(originalSort(models));
      if (id) {
        console.log('id', id, typeof id);
        const foundModel = models.find(
          (model) => model.model_id === parseInt(id, 10),
        );
        if (foundModel) {
          setSelectedModel(foundModel);
        }
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', {
          id: 'network-error',
        });
      } else {
        setError(
          'There was an error retrieving the models. Please try again later.',
        );
      }
    } finally {
      setLoading(false);
    }
  }, [id, customAxios, originalSort]);

  useEffect(() => {
    if (!showDeleteWarning || !showUndeployWarning || showDeployInstructions) {
      setSelectedModel(null);
      getSelfHostedServers();
      fetchDeployList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeleteWarning, fetchDeployList]);

  const fetchBaseModels = useCallback(async () => {
    if (baseModelsLoading || baseModelsError) {
      return;
    }
    let models = [...getPlaygroundBaseModels()];

    models = models.filter(
      (model) =>
        model.available_for_inference &&
        (user.location_preference === 'default' ||
          model.supported_locations.includes(user.location_preference)),
    );

    models.forEach((model) => {
      model.created_at_unix = new Date('june 15 2024').getTime() / 1000;
      model.type = 'base_model';
      model.state = 'deployed';
      model.model_config = {
        base_model: model.model_name,
      };
    });

    setBaseModels(models);
  }, [getPlaygroundBaseModels, user.location_preference]);

  useEffect(() => {
    fetchBaseModels();
  }, [fetchBaseModels]);

  const handleSortModels = useCallback(
    (models, selectedModel = null) => {
      if (!selectedModel) {
        return models;
      }
      const sortedModels = models.filter(
        (model) => model.model_id !== selectedModel.model_id,
      );
      return [selectedModel, ...originalSort(sortedModels)];
    },
    [originalSort],
  );

  useEffect(() => {
    if (selectedModel) {
      if (selectedModel.type === 'base_model') {
        // For base models, don't modify the deployList
        setDeployList(originalSort([...originalDeployList]));
      } else {
        // For regular models, use the existing logic
        setDeployList(handleSortModels([...originalDeployList], selectedModel));
      }
    } else {
      setDeployList(originalSort([...originalDeployList]));
    }

    const scrollBase = document.getElementById('scroll-base');
    if (scrollBase) {
      scrollBase.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (selectedModel) {
      if (
        selectedModel.state !== 'deployed' &&
        !selectedModel.last_deployed_on
      ) {
        setContainerHeight('h-72');
      } else {
        setContainerHeight('md:h-[33rem] h-[39rem]');
      }
    } else {
      setContainerHeight('h-0');
    }
  }, [
    selectedModel,
    originalDeployList,
    modelNotFound,
    handleSortModels,
    originalSort,
  ]);

  const handleSetSelectedModel = useCallback((model) => {
    setSelectedModel((prevModel) =>
      prevModel?.model_id === model?.model_id ? null : model,
    );

    if (modelListRef.current) {
      modelListRef.current.scrollTop = 0;
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const getSelfHostedServers = useCallback(async () => {
    if (!user?.confirmed) {
      toast.error('Please confirm your email address to access this feature.');
      return [];
    }
    try {
      const response = await customAxios.get('tailor/v1/hosted_servers');
      const availableServers = response.data.message.filter(
        (server) => server.url,
      );
      setSelfHostedServers(availableServers);
      return availableServers;
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Error fetching servers, try again later.', {
        id: 'network-error-servers',
      });
    }
    return [];
  }, [customAxios, user.confirmed]);

  const deployModel = useCallback(
    async (model) => {
      const payload = {
        model_name: model.model_name,
      };
      if (serverToUseForDeployment && serverToUseForDeployment.id !== 0) {
        payload.self_hosted_id = serverToUseForDeployment.id;
      }
      try {
        const response = await customAxios.post(
          'tailor/v1/deploy_model',
          payload,
        );
        if (response.status === 200) {
          toast.success('Model deployment initiated.');
          setShowDeployInstructions(true);
          setModelToDeploy(null);
          setServerToUseForDeployment(null);
          setShowSelfHostedOptionsModal(false);
        }
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        switch (error.code) {
          case 'api_key.not_found':
            toast.error('Failed to deploy: API key not found.');
            break;
          case 'base_model.inference.unsupported':
            toast.error(
              'Failed to deploy: Inference not supported for this Base Model.',
            );
            break;
          case 'model.sate_invalid':
            toast.error('Failed to deploy: Model is not in a valid state.');
            break;
          case 'model.files.not_found':
            toast.error('Failed to deploy: Internal issue with model files.');
            break;
          case 'server.not_found':
            toast.error('Failed to deploy: self-hosted server not found.');
            break;
          case 'servers.not_available':
            toast.error(
              'Failed to deploy: No servers available, try again later.',
            );
            break;
          default:
            toast.error('Failed to deploy model, try again later.');
            break;
        }
      }
    },
    [customAxios, serverToUseForDeployment],
  );

  const toggleDeployStatus = useCallback(
    async (e, model) => {
      setModelToDeploy(model);
      e.stopPropagation();
      if (model.state === 'deployed' || model.state === 'failed_undeploy') {
        setShowUndeployWarning(true);
      } else if (model.state === 'dormant' || model.state === 'failed_deploy') {
        const servers = await getSelfHostedServers();
        if (servers.length > 0) {
          setShowSelfHostedOptionsModal(true);
        } else {
          deployModel(model);
        }
      }
    },
    [getSelfHostedServers, deployModel],
  );

  const handleCloseDeployModal = useCallback(() => {
    setShowDeployInstructions(false);
    setShowUndeployWarning(false);
    setModelToDeploy(null);
  }, []);

  const modelStateMessage = {
    start_training: "This model can't be deployed yet",
    failed_training: 'The training of this model failed',
    deploying: 'This model is being deployed',
    deployed: 'This model is deployed',
    undeploying: 'This model is being un-deployed',
    training: 'This model is being trained',
    failed: 'Training of this model failed',
    dormant: 'This model is ready to be deployed',
    failed_deploy: 'The deployment of this model failed, try again later',
    failed_undeploy: 'This model could not be un-deployed at the moment',
  };

  const filteredDeployList = deployList.filter((model) => {
    if (user.location_preference === 'default') {
      return true;
    }
    return model.location === user.location_preference;
  });

  // Update URL params
  const updateUrlParams = useCallback(
    (params) => {
      const newSearchParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          newSearchParams.set(key, value);
        } else {
          newSearchParams.delete(key);
        }
      });
      setSearchParams(newSearchParams);
    },
    [setSearchParams, searchParams],
  );

  // Handle sort
  const handleSort = useCallback(
    (field) => {
      const newSortOrder =
        field === sortBy && sortOrder === 'asc' ? 'desc' : 'asc';
      updateUrlParams({ sortBy: field, sortOrder: newSortOrder });
    },
    [sortBy, sortOrder, updateUrlParams],
  );

  // Handle search
  const handleSearch = useCallback(
    (term) => {
      updateUrlParams({ search: term });
    },
    [updateUrlParams],
  );

  // Handle filter changes
  const handleBaseModelFilter = useCallback(
    (value) => {
      updateUrlParams({ baseModel: value });
    },
    [updateUrlParams],
  );

  const handleDeployStatusFilter = useCallback(
    (value) => {
      updateUrlParams({ deployStatus: value });
    },
    [updateUrlParams],
  );

  const filteredAndSortedModels = useMemo(() => {
    let filtered = [...deployList];
    let filteredBaseModels = [...baseModels];

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();

      if (searchLower.startsWith('base:')) {
        const baseSearchTerm = searchLower.replace('base:', '').trim();
        const baseSearchParts = baseSearchTerm.split(/\s+/);

        filtered = filtered.filter((model) => {
          const baseModelName =
            model.model_config?.base_model?.toLowerCase() || '';
          return baseSearchParts.every((part) => baseModelName.includes(part));
        });

        filteredBaseModels = filteredBaseModels.filter((model) => {
          const baseModelName =
            model.model_config?.base_model?.toLowerCase() || '';
          return baseSearchParts.every((part) => baseModelName.includes(part));
        });
      } else if (searchLower.startsWith('parent:')) {
        const parentSearchTerm = searchLower.replace('parent:', '').trim();
        const parentSearchParts = parentSearchTerm.split(/\s+/);

        filtered = filtered.filter((model) => {
          if (model.parent) {
            const parentName = model.parent.toLowerCase();
            return parentSearchParts.every((part) => parentName.includes(part));
          } else {
            const modelName = model.model_name.toLowerCase();
            return parentSearchParts.every((part) => modelName.includes(part));
          }
        });

        filteredBaseModels = filteredBaseModels.filter((model) => {
          if (model.parent) {
            const parentName = model.parent.toLowerCase();
            return parentSearchParts.every((part) => parentName.includes(part));
          } else {
            const modelName = model.model_name.toLowerCase();
            return parentSearchParts.every((part) => modelName.includes(part));
          }
        });
      } else if (searchLower.startsWith('data:')) {
        const dataSearchTerm = searchLower.replace('data:', '').trim();
        const dataSearchParts = dataSearchTerm.split(/\s+/);

        filtered = filtered.filter((model) => {
          const customDataset =
            model.model_config?.custom_dataset?.toLowerCase() || '';
          const customLogsFilename =
            model.model_config?.custom_logs_filename?.toLowerCase() || '';
          const tags = model.model_config?.tags;

          const tagsMatch = (() => {
            if (Array.isArray(tags)) {
              return tags.some((tag) =>
                dataSearchParts.every((part) =>
                  tag.toLowerCase().includes(part),
                ),
              );
            } else if (typeof tags === 'string') {
              return dataSearchParts.every((part) =>
                tags.toLowerCase().includes(part),
              );
            }
            return false;
          })();

          return dataSearchParts.every(
            (part) =>
              customDataset.includes(part) ||
              customLogsFilename.includes(part) ||
              tagsMatch,
          );
        });

        filteredBaseModels = filteredBaseModels.filter((model) => {
          const customDataset =
            model.model_config?.custom_dataset?.toLowerCase() || '';
          const customLogsFilename =
            model.model_config?.custom_logs_filename?.toLowerCase() || '';
          const tags = model.model_config?.tags;

          const tagsMatch = (() => {
            if (Array.isArray(tags)) {
              console.log('here');
              return tags.some((tag) =>
                dataSearchParts.every((part) =>
                  tag.toLowerCase().includes(part),
                ),
              );
            } else if (typeof tags === 'string') {
              return dataSearchParts.every((part) =>
                tags.toLowerCase().includes(part),
              );
            }
            return false;
          })();

          return dataSearchParts.every(
            (part) =>
              customDataset.includes(part) ||
              customLogsFilename.includes(part) ||
              tagsMatch,
          );
        });
      } else {
        const searchParts = searchLower.split(/\s+/);

        filtered = filtered.filter((model) => {
          const modelName = model.model_name.toLowerCase();
          return searchParts.every((part) => modelName.includes(part));
        });

        filteredBaseModels = filteredBaseModels.filter((model) => {
          const modelName = model.model_name.toLowerCase();
          return searchParts.every((part) => modelName.includes(part));
        });
      }
    }

    if (filterBaseModel && filterBaseModel !== 'all') {
      filtered = filtered.filter((model) =>
        model.model_config?.base_model
          .toLowerCase()
          .includes(filterBaseModel.toLowerCase()),
      );
      filteredBaseModels = filteredBaseModels.filter((model) =>
        model.model_name.toLowerCase().includes(filterBaseModel.toLowerCase()),
      );
    }

    if (filterDeployStatus && filterDeployStatus !== 'all') {
      if (filterDeployStatus === 'not_deployed') {
        filtered = filtered.filter((model) => model.state !== 'deployed');
        filteredBaseModels = filteredBaseModels.filter(
          (model) => model.state !== 'deployed',
        );
      } else {
        filtered = filtered.filter(
          (model) => model.state === filterDeployStatus,
        );
        filteredBaseModels = filteredBaseModels.filter(
          (model) => model.state === filterDeployStatus,
        );
      }
    }

    const sortedModels = [...filtered].sort((a, b) => {
      const getField = (model, field, isAscending) => {
        if (model[field] === undefined || model[field] === null) {
          return isAscending
            ? Number.MAX_SAFE_INTEGER
            : Number.MIN_SAFE_INTEGER;
        }
        return model[field];
      };

      switch (sortBy) {
        case 'model_name':
          return sortOrder === 'asc'
            ? getField(a, 'model_name', true).localeCompare(
                getField(b, 'model_name', true),
              )
            : getField(b, 'model_name', false).localeCompare(
                getField(a, 'model_name', false),
              );
        case 'created_at_unix':
          return sortOrder === 'asc'
            ? getField(a, 'created_at_unix', true) -
                getField(b, 'created_at_unix', true)
            : getField(b, 'created_at_unix', false) -
                getField(a, 'created_at_unix', false);
        case 'last_deployed_on_unix':
          return sortOrder === 'asc'
            ? getField(a, 'last_deployed_on_unix', true) -
                getField(b, 'last_deployed_on_unix', true)
            : getField(b, 'last_deployed_on_unix', false) -
                getField(a, 'last_deployed_on_unix', false);
        case 'last_used_unix':
          return sortOrder === 'asc'
            ? getField(a, 'last_used_unix', true) -
                getField(b, 'last_used_unix', true)
            : getField(b, 'last_used_unix', false) -
                getField(a, 'last_used_unix', false);
        case 'state':
        default:
          return sortOrder === 'asc'
            ? getField(a, 'state', true).localeCompare(
                getField(b, 'state', true),
              )
            : getField(b, 'state', false).localeCompare(
                getField(a, 'state', false),
              );
      }
    });

    return [...sortedModels, ...filteredBaseModels];
  }, [
    deployList,
    baseModels,
    searchTerm,
    filterBaseModel,
    filterDeployStatus,
    sortBy,
    sortOrder,
  ]);

  const noModelsMatch = filteredAndSortedModels.length === 0;

  const renderModelList = useCallback(() => {
    if (noModelsMatch) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-64 text-zinc-600">
          <p className="text-lg font-medium">No models match your criteria</p>
          <p className="mt-2">Try adjusting your filters or search term</p>
        </div>
      );
    }

    const modelComponents = [];

    // Add selected model to the beginning if it exists
    if (selectedModel) {
      if ('hf_repo' in selectedModel) {
        modelComponents.push(
          <BaseModelCard
            key={`selected-base-${selectedModel.hf_repo}`}
            model={selectedModel}
            handleSetSelectedModel={handleSetSelectedModel}
            isSelected={true}
          />,
        );
      } else {
        modelComponents.push(
          <ModelCard
            key={`selected-${selectedModel.model_id}`}
            model={selectedModel}
            handleSetSelectedModel={handleSetSelectedModel}
            toggleDeployStatus={toggleDeployStatus}
            selfHostedServers={selfHostedServers}
            isSelected={true}
          />,
        );
      }
    }

    // Add regular models
    filteredAndSortedModels.forEach((model) => {
      if (
        model.model_id !== selectedModel?.model_id ||
        model.hf_repo !== selectedModel?.hf_repo
      ) {
        if (model.type === 'base_model') {
          modelComponents.push(
            <BaseModelCard
              key={`base-${model.hf_repo}`}
              model={model}
              handleSetSelectedModel={handleSetSelectedModel}
              isSelected={false}
            />,
          );
        } else {
          modelComponents.push(
            <ModelCard
              key={`model-${model.model_id}`}
              model={model}
              handleSetSelectedModel={handleSetSelectedModel}
              toggleDeployStatus={toggleDeployStatus}
              selfHostedServers={selfHostedServers}
              isSelected={false}
            />,
          );
        }
      }
    });

    return modelComponents;
  }, [
    filteredAndSortedModels,
    selectedModel,
    handleSetSelectedModel,
    toggleDeployStatus,
    selfHostedServers,
    noModelsMatch,
  ]);

  if (error) {
    return (
      <div className="flex flex-col max-h-screen min-h-screen overflow-y-hidden bg-zinc-50 font-dmSans">
        <header>
          <div className="flex items-baseline justify-between h-16 p-4 text-xl font-medium text-zinc-800">
            <div>Deploy</div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div className="flex items-center justify-center h-full px-12 text-lg text-center grow text-zinc-800 w-fit">
          {error}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col max-h-screen min-h-screen overflow-y-hidden bg-zinc-50 font-dmSans">
        <header>
          <div className="flex items-baseline justify-between h-16 p-4 text-xl font-medium text-zinc-800">
            <div>Deploy</div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div className="flex items-center justify-center w-full h-full px-12 text-lg text-center grow text-zinc-800">
          <Spinner size={'36px'} borderTopColor={'gray'} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="max-h-screen min-h-screen overflow-y-hidden bg-zinc-50 font-dmSans">
        <header>
          <div className="flex items-baseline justify-between h-16 p-4 text-xl font-medium text-zinc-800 cursor-pointer">
            <div onClick={() => setSearchParams({})}>Deploy</div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div
          className="flex flex-col w-full md:px-4 md:h-svh h-screen overflow-y-scroll  font-dmSans pb-20 "
          id="scroll-base"
        >
          {filteredDeployList.length > 0 && (
            <div className="pt-4 text-xs text-zinc-600 hidden md:block">
              Select a model to see more information
            </div>
          )}

          {deployList?.length === 0 && !loading && (
            <div className="flex items-center justify-center h-64">
              <div className="flex items-center justify-around w-full text-sm text-center lg:text-lg grow text-zinc-800">
                No models have been fine-tuned yet
              </div>
            </div>
          )}

          <div
            className={clsx(
              'transition-height duration-500 ease-in-out shrink-0 relative',
              containerHeight,
              selectedModel ? 'mt-11 md:mt-0' : 'mt-0',
            )}
          >
            {selectedModel &&
              !loading &&
              (selectedModel.state === 'deployed' ||
              selectedModel.last_deployed_on ? (
                <>
                  <TokenUsageChart
                    model={selectedModel}
                    setModelNotFound={setModelNotFound}
                    closeModel={() => handleSetSelectedModel(null)}
                  />
                  {selectedModel.state !== 'deployed' && (
                    <>
                      <div className="flex justify-around items-center absolute top-1 right-3 md:top-[41px] md:right-2">
                        <ThreeDotsOptions
                          handleDelete={() => setShowDeleteWarning(true)}
                        />
                        <DeleteModelDropdown
                          handleDelete={() => setShowDeleteWarning(true)}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className={clsx(
                    'flex flex-col items-center justify-around h-64 text-zinc-600 relative px-4 ',
                    containerHeight === 'h-72' ? 'opacity-100' : 'opacity-0',
                    'transition-opacity delay-300 ease-in-out',
                  )}
                >
                  <span className="w-full h-6 text-lg text-center">
                    {selectedModel.state === 'dormant' &&
                      'Model is not deployed'}
                  </span>
                  <div className="w-full text-center ">
                    <div className="text-lg font-medium text-zinc-800">
                      {selectedModel.model_name}
                    </div>
                    <div className="font-medium capitalize text-zinc-500">
                      {selectedModel?.state?.split('_').join(' ')}:{' '}
                      {modelStateMessage[selectedModel?.state]}
                    </div>

                    {(selectedModel.state !== 'training' ||
                      selectedModel.state !== 'start_training') && (
                      <div className="flex justify-around mt-10">
                        <button
                          className="flex items-center gap-1 px-1 border-b text-zinc-900 border-zinc-300"
                          onClick={() => setShowDeleteWarning(true)}
                        >
                          <TrashIcon className="w-4 h-4" /> Delete Model
                        </button>
                      </div>
                    )}
                  </div>

                  <button
                    className="absolute p-1 rounded-full -top-9 right-2 bg-zinc-100 text-zinc-800 md:hidden"
                    onClick={() => handleSetSelectedModel(null)}
                  >
                    <XMarkIcon className="w-5 h-5" />
                  </button>
                </div>
              ))}
          </div>
          {deployList.length > 0 && (
            <Fragment>
              <div className="flex items-center self-end h-12 absolute md:top-2 right-4 text-right top-12">
                <div className="h-10 ">
                  <div className="flex flex-col">
                    <div>
                      Showing results for{' '}
                      <span className="capitalize text-zinc-600">
                        {user.location_preference === 'uk'
                          ? 'UK'
                          : user.location_preference === 'default'
                            ? 'everywhere'
                            : user.location_preference}
                      </span>
                    </div>
                    <div className="text-xs">
                      Change your location preference in{' '}
                      <Link
                        to="/tailor/settings#location"
                        className="text-indigo-600"
                      >
                        Settings
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 pb-10 space-y-4">
                <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                  <div className="relative flex-grow w-full md:w-auto">
                    <input
                      type="text"
                      placeholder="Search models..."
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border rounded-md h-10 border-none shadow-md"
                    />
                    <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <div className="absolute right-4 top-2 h-4 w-4 text-gray-400">
                      <Tooltip
                        arrow={false}
                        placement="top-end"
                        style={'light'}
                        content="Search for models by name, use spaces to match multiple words.
                    'base:' to search for base models (eg. base:llama 3.1)
                    'parent:' to search for parent models (eg. parent:llama 3.1)
                    'data:' to search for models with specific data: custom dataset, custom logs filename, or tags (eg. data:gpt-4o)
                    "
                        className="shadow-md shadow-black/25 z-50 w-[20rem] md:w-[50rem] text-sm text-gray-600 whitespace-pre-line"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                  <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="flex items-center self-end px-4 py-2 border rounded-md md:hidden"
                  >
                    <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
                    Filters
                  </button>
                  <div
                    className={`flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full md:w-auto ${showFilters ? 'block' : 'hidden md:flex'}`}
                  >
                    <Listbox
                      value={filterBaseModel}
                      onChange={handleBaseModelFilter}
                    >
                      <div className="relative">
                        <Listbox.Button className="h-10 border-none relative w-full cursor-default rounded-md border-zinc-600 bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 min-w-64 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">
                            {baseModelOptions.find(
                              (option) => option.id === filterBaseModel,
                            )?.name || 'All Base Models'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 z-50 focus:outline-none sm:text-sm">
                            {baseModelOptions.map((option) => (
                              <Listbox.Option
                                key={option.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? 'bg-indigo-100 text-indigo-900'
                                      : 'text-gray-900'
                                  }`
                                }
                                value={option.id}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {option.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>

                    <Listbox
                      value={filterDeployStatus}
                      onChange={handleDeployStatusFilter}
                    >
                      <div className="relative">
                        <Listbox.Button className="h-10 relative w-full cursor-default rounded-md border-zinc-600 border-none  bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm min-w-48">
                          <span className="block truncate">
                            {deployStatusOptions.find(
                              (option) => option.id === filterDeployStatus,
                            )?.name || 'All Deploy Statuses'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 z-50 focus:outline-none sm:text-sm">
                            {deployStatusOptions.map((option) => (
                              <Listbox.Option
                                key={option.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? 'bg-indigo-100 text-indigo-900'
                                      : 'text-gray-900'
                                  }`
                                }
                                value={option.id}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {option.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
              </div>

              {/* Table header with sorting - hidden on mobile */}
              <div className="hidden md:grid grid-cols-6 px-2 pb-1 mx-1">
                <div
                  className="text-sm font-medium xl:text-base text-zinc-800 md:col-span-2 cursor-pointer md:pl-4"
                  onClick={() => handleSort('model_name')}
                >
                  Model{' '}
                  {sortBy === 'model_name' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
                <div
                  className="text-sm font-medium xl:text-base text-zinc-800 justify-self-center cursor-pointer"
                  onClick={() => handleSort('created_at_unix')}
                >
                  Created On{' '}
                  {sortBy === 'created_at_unix' &&
                    (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
                <div
                  className="text-sm font-medium xl:text-base text-zinc-800 justify-self-center cursor-pointer"
                  onClick={() => handleSort('last_deployed_on_unix')}
                >
                  Deployed On{' '}
                  {sortBy === 'last_deployed_on_unix' &&
                    (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
                <div
                  className="text-sm font-medium xl:text-base text-zinc-800 justify-self-center cursor-pointer md:pr-5"
                  onClick={() => handleSort('last_used_unix')}
                >
                  Last used{' '}
                  {sortBy === 'last_used_unix' &&
                    (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
                <div
                  className="text-sm font-medium xl:text-base text-zinc-800 justify-self-end cursor-pointer md:pr-4"
                  onClick={() => handleSort('state')}
                >
                  Status{' '}
                  {sortBy === 'state' ? (
                    sortOrder === 'asc' ? (
                      '↑'
                    ) : (
                      '↓'
                    )
                  ) : (
                    <span className="text-transparent">↓</span>
                  )}
                </div>
              </div>

              {/* Render filtered and sorted models */}
              <div
                id="model-list"
                ref={modelListRef}
                className="flex flex-col w-full gap-3 h-svh items-center overflow-y-scroll pt-2"
              >
                {renderModelList()}
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <DeleteModelWaringModal
        showModal={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
        model={selectedModel}
      />
      <UndeployWarningModal
        showModal={showUndeployWarning}
        onClose={handleCloseDeployModal}
        model={modelToDeploy}
      />
      <DeployInstructions
        showModal={showDeployInstructions}
        onClose={handleCloseDeployModal}
        model={modelToDeploy}
      />
      <SelfHostedOptionsModal
        showModal={showSelfHostedOptionsModal}
        closeModal={() => setShowSelfHostedOptionsModal(false)}
        model={modelToDeploy}
        deployModel={deployModel}
        setServerToUseForDeployment={setServerToUseForDeployment}
        serverToUseForDeployment={serverToUseForDeployment}
        selfHostedServers={selfHostedServers}
      />
    </>
  );
};

export default TailorDeploy;
